'use client'

import classnames from 'classnames'
import styles from './TimelineDatesCarousel.module.scss'
import 'swiper/css'
import Swiper from 'swiper'
import { SwiperOptions } from 'swiper/types'
import { useEffect, useRef, useState } from 'react'
import useWindowResize from '@/hooks/use-window-resize'
import useBreakpoint from '@/hooks/use-breakpoint'
import useI18n from '@/hooks/use-i18n'
import Icon from '@/components/Icon/Icon'

type SanityTimelineDatesCarouselProps = {
  items: SanityTimelineItem[]
  className?: string
  setActiveIndex?: (index: number) => void
  activeIndex: number
}

const TimelineDatesCarousel = ({ className, items, setActiveIndex, activeIndex }: SanityTimelineDatesCarouselProps) => {
  const carousel = useRef<Swiper | null>(null)
  const carouselRef = useRef<HTMLDivElement | null>(null)
  const resizeKey = useWindowResize()
  const [isInitialized, setIsInitialized] = useState(false)
  const { isMobile } = useBreakpoint()
  const { i18n } = useI18n()

  useEffect(() => {
    if (!carousel.current || !isInitialized) return
    carousel?.current?.slideTo(activeIndex)
  }, [activeIndex, isInitialized])

  useEffect(() => {
    if (isMobile === null) return
    if (!carouselRef.current) return

    if (carousel.current) {
      carousel.current.destroy()
      setIsInitialized(false)
    }

    const settings: SwiperOptions = {
      slidesPerView: 1,
      spaceBetween: isMobile ? 40 : 160,
      allowTouchMove: false,
      on: {
        init: function () {
          setIsInitialized(true)
        },
      },
    }

    carousel.current = new Swiper(carouselRef.current, settings)

    return () => {
      if (carousel.current) {
        carousel.current.destroy()
      }
    }
  }, [resizeKey, isMobile])

  return (
    <div className={classnames(styles.TimelineDatesCarousel, className)}>
      <div
        className={styles.carouselWrapper}
        ref={carouselRef}
      >
        <div className={styles.activeYearShape} />
        <Icon
          className={styles.iconUp}
          name="caretDown"
        />
        <ul className={classnames('swiper-wrapper', styles.carouselInner)}>
          {items.map((item, i) => (
            <li
              className={classnames('swiper-slide', styles.carouselItem, { [styles.active]: activeIndex === i })}
              key={i}
            >
              <button
                onClick={() => {
                  if (setActiveIndex) {
                    setActiveIndex(i)
                  }
                  carousel.current?.slideTo(i)
                }}
                className={styles.year}
                aria-label={i18n('timelineYearAndTitleAria', { year: item.year, title: item.title })}
              >
                {item.year}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

TimelineDatesCarousel.displayName = 'TimelineDatesCarousel'

export default TimelineDatesCarousel
