'use client'

import classnames from 'classnames'
import styles from './Breadcrumbs.module.scss'
import Link from '@/components/Link/Link'
import Icon from '@/components/Icon/Icon'
import { Fragment } from 'react'
import useI18n from '@/hooks/use-i18n'

type BreadcrumbsProps = {
  className?: string
  links: SanityLink[]
}

const Breadcrumbs = ({ className, links }: BreadcrumbsProps) => {
  const { i18n } = useI18n()

  if (!links?.length) return null

  return (
    <nav
      className={classnames(styles.Breadcrumbs, className)}
      aria-label={i18n('ariaBreadcrumbs')}
    >
      {links.map((link, i) => {
        return (
          <Fragment key={i}>
            {i !== 0 && (
              <Icon
                name="caretRight"
                className={styles.icon}
              />
            )}
            <Link
              link={link}
              key={i}
              className={styles.link}
            />
          </Fragment>
        )
      })}
    </nav>
  )
}

Breadcrumbs.displayName = 'Breadcrumbs'

export default Breadcrumbs
