'use client'

import classnames from 'classnames'
import styles from './PressHighlightListing.module.scss'
import { useContext, useEffect, useRef, useState } from 'react'
import ThreeUpPressHighlightCard from '@/sections/ThreeUp/ThreeUpPressHighlightCard/ThreeUpPressHighlightCard'
import useCurrentPage from '@/hooks/use-current-page'
import { GlobalSettingsContext } from '@/context/GlobalSettings'
import Button from '@/components/Button/Button'
import useI18n from '@/hooks/use-i18n'
import AnimateContainer from '@/components/AnimateContainer/AnimateContainer'
import useBreakpoint from '@/hooks/use-breakpoint'

const PER_PAGE = 8

const PressHighlightListing = ({ className, items }: SanityPressHighlightListing) => {
  const [currentItems, setCurrentItems] = useState<SanityPressHighlight[]>([])
  const [sortedItems, setSortedItems] = useState<SanityPressHighlight[]>([])
  const { isPreviewMode } = useContext(GlobalSettingsContext)
  const { currentLanguage } = useCurrentPage()
  const isFetchingRef = useRef(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const { i18n } = useI18n()
  const { isMobile } = useBreakpoint()
  const perPage = isMobile ? 5 : PER_PAGE

  useEffect(() => {
    const fetchItems = async () => {
      if (isFetchingRef.current) return
      isFetchingRef.current = true
      try {
        const response = await fetch('/api/get-press-highlights', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            language: currentLanguage,
            isPreview: isPreviewMode,
          }),
        })
        if (response.ok) {
          const data = await response.json()
          setTotalItems(data.length)
          setCurrentItems(data)
        }
        return
      } catch (err) {
        // eslint-disable-next-line
        console.log({ err })
      }

      isFetchingRef.current = false
    }

    fetchItems()
  }, [isPreviewMode, currentLanguage])

  useEffect(() => {
    if (!currentItems.length && !items.length) return
    const itemsToSort = currentItems.length ? currentItems : items
    const sorted = itemsToSort.sort((itemA, itemB) => {
      const dateATime = new Date(itemA.publishedDate).getTime()
      const dateBTime = new Date(itemB.publishedDate).getTime()
      return dateBTime - dateATime
    })
    setSortedItems(sorted)
  }, [currentItems, items])

  if (!sortedItems.length) return null

  return (
    <div className={classnames(styles.PressHighlightListing, className)}>
      <div className={styles.inner}>
        <AnimateContainer
          animationType="fadeIn"
          element="ul"
          className={styles.list}
        >
          {sortedItems.map((item, i) => {
            const isWithinPageLimits = i < perPage * currentPage
            if (!isWithinPageLimits) return null
            return (
              <ThreeUpPressHighlightCard
                className={styles.item}
                key={i}
                element="li"
                {...item}
              />
            )
          })}
        </AnimateContainer>
        {perPage * currentPage < totalItems && (
          <div className={styles.buttonContainer}>
            <Button
              className={styles.button}
              label={i18n('loadMore')}
              onClick={() => {
                setCurrentPage(prev => prev + 1)
              }}
              icon="caretRight"
            />
          </div>
        )}
      </div>
    </div>
  )
}

PressHighlightListing.displayName = 'PressHighlightListing'

export default PressHighlightListing
