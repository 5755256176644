'use client'

import classnames from 'classnames'
import styles from './MerchandiseListing.module.scss'
import MerchandiseListingItem from '@/sections/MerchandiseListing/MerchandiseListingItem/MerchandiseListingItem'
import AnimateContainer from '@/components/AnimateContainer/AnimateContainer'
import SanityImage from '@/components/SanityImage/SanityImage'

const MerchandiseListing = ({ className, title, items, subtitle, image }: SanityMerchandiseListing) => {
  if (!title) return null
  return (
    <div className={classnames(styles.MerchandiseListing, className)}>
      <div className={styles.inner}>
        <AnimateContainer
          animationType="fadeIn"
          className={styles.content}
        >
          <div className={styles.header}>
            <h1 className={styles.title}>{title}</h1>
          </div>
          <div className={styles.subHeader}>
            <h2 className={styles.subtitle}>{subtitle}</h2>
          </div>
          <div className={styles.inner}>
            <div className={styles.itemsInner}>
              {items.map((item, i) => (
                <div
                  key={i}
                  className={styles.item}
                >
                  <MerchandiseListingItem {...item} />
                </div>
              ))}
            </div>
          </div>
        </AnimateContainer>
      </div>
      <div className={styles.mediaContainer}>
        {image && (
          <SanityImage
            image={image}
            className={styles.image}
            breakpoints={{
              xs: {
                width: 700,
                image,
                options: {
                  height: 1400,
                  fit: 'crop',
                },
              },
              tablet: {
                width: 2000,
                image,
              },
              desktop: {
                width: 2500,
                image,
              },
            }}
          />
        )}
      </div>
    </div>
  )
}

MerchandiseListing.displayName = 'MerchandiseListing'

export default MerchandiseListing
