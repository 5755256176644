'use client'

import classnames from 'classnames'
import styles from './EmailSignup.module.scss'
import EmailSignupInput from '@/components/EmailSignupInput/EmailSignupInput'
import useI18n from '@/hooks/use-i18n'
import HeaderWithCtas from '@/components/HeaderWithCtas/HeaderWithCtas'
import AnimateContainer from '@/components/AnimateContainer/AnimateContainer'

const EmailSignup = ({ className, header }: SanityEmailSignup) => {
  const { i18n } = useI18n()
  return (
    <div className={classnames(styles.EmailSignup, className)}>
      <div className={styles.inner}>
        {header?.headingContent && (
          <AnimateContainer
            animationType="fadeInUp"
            className={styles.header}
          >
            <HeaderWithCtas
              alignItems="left"
              headingContent={header.headingContent}
              mobileTitleSize={header.mobileTitleSize}
              desktopTitleSize={header.desktopTitleSize}
            />
          </AnimateContainer>
        )}
        <div className={styles.smallHeader}>{i18n('signUpWithEmail')}</div>
        <EmailSignupInput variant="emailSignupSection" />
      </div>
    </div>
  )
}

EmailSignup.displayName = 'EmailSignup'

export default EmailSignup
