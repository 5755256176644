import { useState, useEffect, useRef } from 'react'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import gsap from 'gsap'
import useWindowResize from '@/hooks/use-window-resize'

ScrollTrigger.config({
  ignoreMobileResize: true,
})

gsap.registerPlugin(ScrollTrigger)

export const USE_IN_VIEW_DEFAULTS = {
  fireOnce: true,
  scrolltriggerStart: 'top bottom',
  scrolltriggerEnd: 'bottom top',
}

type UseInViewProps = {
  fireOnce?: boolean
  scrolltriggerStart?: string
  scrolltriggerEnd?: string
}

export default function useInView(props?: UseInViewProps) {
  const options: UseInViewProps = { ...USE_IN_VIEW_DEFAULTS, ...props }
  const [elementToObserve, setElementToObserve] = useState<HTMLElement | null>(null)
  const [isInView, setIsInView] = useState(false)
  const hasFiredInView = useRef(false)
  const scrollTriggerRef = useRef<ScrollTrigger>()
  const resizeKey = useWindowResize()

  useEffect(() => {
    if (!elementToObserve || (hasFiredInView.current && options.fireOnce)) return

    if (scrollTriggerRef.current) {
      scrollTriggerRef.current.kill()
    }

    scrollTriggerRef.current = new ScrollTrigger({
      trigger: elementToObserve,
      start: options.scrolltriggerStart,
      end: options.scrolltriggerEnd,
      onEnter: () => {
        setIsInView(true)

        if (options.fireOnce && scrollTriggerRef.current) {
          scrollTriggerRef.current.kill()
        }
      },
      onEnterBack: () => {
        setIsInView(true)

        if (options.fireOnce && scrollTriggerRef.current) {
          scrollTriggerRef.current.kill()
        }
      },
      onLeave: () => {
        if (!options.fireOnce) {
          setIsInView(false)
        }
      },
      onLeaveBack: () => {
        if (!options.fireOnce) {
          setIsInView(false)
        }
      },
    })
  }, [elementToObserve, options.fireOnce, options.scrolltriggerStart, options.scrolltriggerEnd, resizeKey])

  useEffect(() => {
    return () => {
      if (scrollTriggerRef.current) {
        scrollTriggerRef.current.kill()
      }
    }
  }, [])

  return { setElementToObserve, isInView, setIsInView }
}
