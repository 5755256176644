import { AsYouType } from 'libphonenumber-js'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
/**
 * Formats a phone number according international standars // Outputs: '+1 213 373 4'
 *
 * @param {string} value - The phone number to format.
 * @returns {string} The formatted phone number.
 */
export const formatPhoneNumber = (value: string): string => {
  const countryCode = value.startsWith('+') ? undefined : 'US'
  if (value.startsWith('1')) {
    value = `+${value}`
  }

  const asYouType = new AsYouType(countryCode)
  return asYouType.input(value)
}

export const handlePhoneNumber: handleChangeProps = (fieldName, event, handleChange) => {
  const inputValue = event?.target.value
  const newEvent = {
    ...event,
    target: {
      ...event?.target,
      value: formatPhoneNumber(inputValue ?? ''),
    },
  }
  handleChange(fieldName)(newEvent as FormFieldChangeEvent)
}

/**
 * This function takes the raw input from the date of visit field, formats it to ensure it
 * matches the expected date format (YYYY/MM/DD)
 *
 * @param {string} fieldName - field name
 * @param {FormFieldChangeEvent} event - The event object
 * @param {(fieldName: string) => (event: FormFieldChangeEvent) => void} handleChange - function that updates the form's state.
 */
export const handleDateOfVisit: handleChangeProps = (fieldName, event, handleChange) => {
  const inputValue = event?.target.value

  const newEvent = {
    ...event,
    target: {
      ...event?.target,
      value: inputValue ?? '',
    },
  }
  handleChange(fieldName)(newEvent as FormFieldChangeEvent)
}

/**
 *  This function takes the raw input from the time of visit field, formats it to ensure it
 * matches the expected time format (HH:MM)
 *
 * @param {string} fieldName - form field name.
 * @param {FormFieldChangeEvent} event - The event object
 * @param {(fieldName: string) => (event: FormFieldChangeEvent) => void} handleChange - function that updates the form's state.
 */
export const handleTimeOfVisit: handleChangeProps = (fieldName, event, handleChange) => {
  const inputValue = event?.target.value

  const formatTimeString = (timeString: string) => {
    let cleaned = timeString.replace(/[^\d:]/g, '')
    cleaned = cleaned.slice(0, 5)
    if (cleaned.length > 2 && cleaned[2] !== ':') {
      cleaned = `${cleaned.slice(0, 2)}:${cleaned.slice(2)}`
    }

    return cleaned
  }

  const newEvent = {
    ...event,
    target: {
      ...event?.target,
      value: formatTimeString(inputValue ?? ''),
    },
  }
  handleChange(fieldName)(newEvent as FormFieldChangeEvent)
}

/**
 * Validates a specific field in a form and updates the validation errors state.
 *
 * @param {string} field - The name of the field.
 * @param {string} value - The value.
 * @returns {boolean} isValid - Returns true if the field is valid, otherwise false.
 */
export const validateField = (field: string, value: string): boolean => {
  let isValid = true

  if (field === 'emailAddress') {
    const emailRegex = /^[^\s@]+@[a-zA-Z]+(\.[a-zA-Z]{2,})+$/
    if (!emailRegex.test(value)) {
      isValid = false
    }
  }

  if (field === 'phoneNumber') {
    const phoneNumber = parsePhoneNumberFromString(value, 'US')
    if (!value || !phoneNumber || !phoneNumber.isValid()) {
      isValid = false
    }
  }

  if (field === 'dateOfVisit') {
    const dateRegex = /^(19|20)\d\d-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/
    if (!dateRegex.test(value)) {
      isValid = false
    } else {
      const currentDate = new Date()
      const inputDate = new Date(value.replace(/[/]/g, '-'))

      if (isNaN(inputDate.getTime()) || inputDate > currentDate) {
        isValid = false
      }
    }
  }

  if (field === 'timeOfVisit') {
    const timeRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/
    if (!timeRegex.test(value)) {
      isValid = false
    }
  }

  return isValid
}
