'use client'

import classnames from 'classnames'
import styles from './EventDetails.module.scss'
import Widont from '@/components/Widont/Widont'
import useI18n from '@/hooks/use-i18n'
import Button from '@/components/Button/Button'
import SanityImage from '@/components/SanityImage/SanityImage'
import { useCallback, useState } from 'react'

const EventDetails = ({
  className,
  cta,
  description,
  eventDetails,
  image,
  inviteCode,
  secondaryCta,
  showEventDetails,
  subtitle,
  title,
}: SanityEventDetails) => {
  const { i18n } = useI18n()
  const [inviteCodeInputValue, setInviteCodeInputValue] = useState('')
  const [error, setError] = useState('')

  const handleButtonClick = useCallback(() => {
    if (inviteCodeInputValue === inviteCode) {
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      const windowObj: any = window
      windowObj.open(cta?.link, '_blank').focus()
    } else {
      setError(i18n('inviteCodeInvalid') as string)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteCodeInputValue, inviteCode, cta?.link])

  if (!title || !description || !eventDetails || !image?.asset?.url) {
    return null
  }

  return (
    <div className={classnames(styles.EventDetails, className)}>
      <div className={styles.inner}>
        <div className={styles.textSide}>
          <h1 className={styles.title}>
            <Widont text={title} />
          </h1>
          {subtitle && (
            <p className={styles.subtitle}>
              <span className={styles.subtitle__asterisk}>*</span>
              <span className={styles.subtitle__text}>
                <Widont text={subtitle} />
              </span>
            </p>
          )}
          <p className={styles.description}>{description}</p>
          {showEventDetails && (
            <div className={styles.eventDetailsContainer}>
              <p className={styles.eventDetailsTitle}>
                <span className={styles.eventDetailsTitle__dot} />
                <span className={styles.eventDetailsTitle__title}>{i18n('eventDetails')}</span>
              </p>
              <p className={styles.eventDetailsText}>{eventDetails}</p>
            </div>
          )}
          {inviteCode && cta ? (
            <div className={styles.inviteCodeContainer}>
              <input
                type="text"
                className={styles.inviteCodeContainer__input}
                placeholder={i18n('enterInviteCode')}
                onChange={e => {
                  setInviteCodeInputValue(e.target.value)
                  setError('')
                }}
              />
              <Button
                className={styles.inviteCodeContainer__button}
                onClick={handleButtonClick}
                label={cta?.label}
                icon={cta?.icon || 'caretRight'}
                primary
              />
            </div>
          ) : (
            <div className={styles.ctaWrapper}>
              {cta?.link && (
                <Button
                  link={cta}
                  primary
                />
              )}
              {secondaryCta?.link && (
                <Button
                  link={secondaryCta}
                  secondary
                />
              )}
            </div>
          )}
          {error && <p className={styles.error}>{error}</p>}
        </div>
        <div className={styles.imageSide}>
          <div className={styles.imageContainer}>
            <SanityImage
              className={styles.image}
              image={image}
              breakpoints={{
                tablet: {
                  width: 1200,
                  image,
                },
                xs: {
                  width: 800,
                  image,
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

EventDetails.displayName = 'EventDetails'

export default EventDetails
