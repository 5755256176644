'use client'

import classnames from 'classnames'
import styles from './ThreeUpBlogPostCard.module.scss'
import Link from '@/components/Link/Link'
import Button from '@/components/Button/Button'
import useI18n from '@/hooks/use-i18n'
import { useRef } from 'react'
import { getFormattedDateString } from '@/utils'
import Widont from '@/components/Widont/Widont'
import AnimateContainer from '@/components/AnimateContainer/AnimateContainer'

type ThreeUpBlogPostCardProps = SanityPage & {
  className?: string
}

const ThreeUpBlogPostCard = ({ className, title, blogPostData, slug, _createdAt }: ThreeUpBlogPostCardProps) => {
  const datePublished = blogPostData?.publishedDate || _createdAt
  const { i18n } = useI18n()
  const buttonImpHandleRef = useRef<ButtonImperativeHandle | null>(null)

  if (!title || !blogPostData || !slug?.current) return null

  return (
    <AnimateContainer
      element="article"
      animationType="fadeIn"
      className={classnames(styles.ThreeUpBlogPostCard, className)}
    >
      <Link
        link={{
          label: '',
          linkType: 'internal',
          link: {
            _type: 'blogPost',
            slug: slug.current,
          },
        }}
        onMouseEnter={() => {
          buttonImpHandleRef.current?.setIsHover(true)
        }}
        onMouseLeave={() => {
          buttonImpHandleRef.current?.setIsHover(false)
        }}
        className={styles.link}
      >
        <div className={styles.categoryPublishedAtContainer}>
          {blogPostData.blogCategory.title && (
            <span className={styles.category}>{blogPostData.blogCategory.title}</span>
          )}
          &nbsp;-&nbsp;
          <span className={styles.datePublished}>{getFormattedDateString(datePublished)}</span>
        </div>
        <h1 className={styles.title}>
          <Widont text={title} />
        </h1>
        <div className={styles.buttonContainer}>
          <Button
            className={styles.button}
            element="span"
            label={i18n('readMore')}
            disableHoverAnimation
            ref={ref => {
              if (!ref) return
              buttonImpHandleRef.current = ref
            }}
            icon="caretRight"
          />
        </div>
      </Link>
    </AnimateContainer>
  )
}

ThreeUpBlogPostCard.displayName = 'ThreeUpBlogPostCard'

export default ThreeUpBlogPostCard
