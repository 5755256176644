'use client'

import classnames from 'classnames'
import styles from './Stats.module.scss'
import SanityImage from '@/components/SanityImage/SanityImage'
import AnimateContainer from '@/components/AnimateContainer/AnimateContainer'

const Stats = ({ className, title, image, items }: SanityStats) => {
  if (!title || !image?.asset?.url || !items?.length) return null

  return (
    <div className={classnames(styles.Stats, className)}>
      <div className={styles.inner}>
        <AnimateContainer
          animationType="fadeIn"
          className={styles.textSide}
        >
          <h1 className={styles.title}>{title}</h1>
          <div className={styles.items}>
            {items.map((item, i) => (
              <dl
                className={styles.item}
                key={i}
              >
                <dt className={styles.item__number}>{item.largeNumber}</dt>
                <dd className={styles.item__text}>{item.text}</dd>
              </dl>
            ))}
          </div>
        </AnimateContainer>
        <AnimateContainer
          animationType="fadeIn"
          className={styles.imageSide}
        >
          <div className={styles.imageContainer}>
            <SanityImage
              image={image}
              breakpoints={{
                tablet: {
                  width: 1200,
                  image,
                },
                xs: {
                  width: 800,
                  image,
                },
              }}
              className={styles.image}
            />
          </div>
        </AnimateContainer>
      </div>
    </div>
  )
}

Stats.displayName = 'Stats'

export default Stats
