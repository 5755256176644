import styles from './SectionContainer.module.scss'
import { cloneElement, useEffect, useRef } from 'react'
import { buildIdFromText } from '@/utils'
import useStore from '@/store'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import gsap from 'gsap'

ScrollTrigger.config({
  ignoreMobileResize: true,
})

gsap.registerPlugin(ScrollTrigger)

const SectionContainer = ({
  _type,
  children,
  cmsTitle,
  sectionsLength,
  sectionBackground,
  nextSectionBackground,
  previousSectionBackground,
  nextSectionType,
  sectionIndex,
  allowSectionBackgroundChange,
  setAllowSectionBackgroundChange,
}: SectionContainerProps) => {
  const containerRef = useRef<HTMLElement>(null)
  const id = buildIdFromText(cmsTitle)
  const scrollTrigger = useRef<ScrollTrigger>()
  const setNavTheme = useStore(state => state.setNavTheme)
  const setNavScrollingOverSection = useStore(state => state.setNavScrollingOverSection)
  const navSettingDebounceTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>()

  useEffect(() => {
    if (sectionIndex !== 0) return
    if (_type === 'footer') return

    if (navSettingDebounceTimeoutRef.current) {
      clearTimeout(navSettingDebounceTimeoutRef.current)
    }

    navSettingDebounceTimeoutRef.current = setTimeout(() => {
      setNavTheme(sectionBackground)
      setNavScrollingOverSection(_type)
      setAllowSectionBackgroundChange(true)
      setTimeout(() => {
        ScrollTrigger.refresh()
      }, 50)
    }, 100)
  }, [setNavTheme, setNavScrollingOverSection, _type, sectionBackground, sectionIndex, setAllowSectionBackgroundChange])

  useEffect(() => {
    if (!allowSectionBackgroundChange) return

    if (scrollTrigger.current) {
      scrollTrigger.current.kill()
    }

    scrollTrigger.current = new ScrollTrigger({
      trigger: containerRef.current,
      start: 'top-=1 top',
      end: 'bottom top',
      onEnter: () => {
        setNavTheme(sectionBackground)
        setNavScrollingOverSection(_type)
      },
      onEnterBack: () => {
        setNavTheme(sectionBackground)
        setNavScrollingOverSection(_type)
      },
    })
  }, [id, setNavTheme, sectionBackground, _type, setNavScrollingOverSection, allowSectionBackgroundChange])

  useEffect(() => {
    return () => {
      scrollTrigger.current?.kill()
    }
  }, [])

  const newElement = cloneElement(children, {
    sectionId: id,
    sectionBackground,
    sectionIndex,
  })

  return (
    <section
      id={id}
      ref={containerRef}
      data-component={_type}
      className={styles.SectionContainer}
      data-sections-length={sectionsLength}
      data-section-background={sectionBackground}
      data-section-background-previous={previousSectionBackground}
      data-section-background-next={nextSectionBackground}
      data-section-next-section-type={nextSectionType}
    >
      {newElement}
    </section>
  )
}

SectionContainer.displayName = 'SectionContainer'

export default SectionContainer
