'use client'

import classnames from 'classnames'
import styles from './LocationHeroImageCarousel.module.scss'
import SanityImage from '@/components/SanityImage/SanityImage'
import 'swiper/css'
import 'swiper/css/effect-fade'
import Swiper from 'swiper'
import { EffectFade, Autoplay } from 'swiper/modules'
import { SwiperOptions } from 'swiper/types'
import { useEffect, useRef, useState } from 'react'
import useWindowResize from '@/hooks/use-window-resize'
import useI18n from '@/hooks/use-i18n'

Swiper.use([EffectFade, Autoplay])

type LocationHeroImageCarouselProps = {
  images: SanityImage[]
  className?: string
}

const LocationHeroImageCarousel = ({ className, images }: LocationHeroImageCarouselProps) => {
  const carousel = useRef<Swiper | null>(null)
  const carouselRef = useRef<HTMLDivElement | null>(null)
  const resizeKey = useWindowResize()
  const [activeIndex, setActiveIndex] = useState(0)
  const { i18n } = useI18n()

  useEffect(() => {
    if (!carouselRef.current) return

    if (carousel.current) {
      carousel.current.destroy()
    }

    const settings: SwiperOptions = {
      slidesPerView: 1,
      effect: 'fade',
      fadeEffect: { crossFade: true },
      loop: true,
      autoplay: {
        delay: 5000,
      },
    }

    carousel.current = new Swiper(carouselRef.current, settings)

    carousel.current.on('slideChange', function () {
      if (!carousel.current) return
      setActiveIndex(carousel.current?.activeIndex)
    })

    return () => {
      if (carousel.current) {
        carousel.current.destroy()
      }
    }
  }, [resizeKey])

  if (!images.length) return null

  return (
    <div className={classnames(styles.LocationHeroImageCarousel, className)}>
      <div className={styles.carouselContainerMaster}>
        <div
          className={styles.carouselContainer}
          ref={carouselRef}
        >
          <div className={classnames(styles.carouselContainerInner, 'swiper-wrapper')}>
            {images.map((image, i) => {
              return (
                <div
                  className={classnames(styles.imageContainer, 'swiper-slide')}
                  key={i}
                >
                  <SanityImage
                    className={styles.image}
                    image={image}
                    priority
                    breakpoints={{
                      tablet: {
                        width: 1500,
                        image: image,
                        options: {
                          height: 1607,
                          fit: 'crop',
                        },
                      },
                      mobile: {
                        width: 1000,
                        image: image,
                        options: {
                          height: 1150,
                          fit: 'crop',
                        },
                      },
                      xs: {
                        width: 800,
                        image: image,
                        options: {
                          height: 900,
                          fit: 'crop',
                        },
                      },
                    }}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>
      {images.length > 1 && (
        <div className={styles.pagination}>
          {images.map((_, i) => (
            <button
              key={i}
              className={classnames(styles.paginationButton, { [styles.isActive]: i === activeIndex })}
              onClick={() => {
                carousel.current?.slideTo(i)
              }}
              aria-label={i18n('ariaGoToSlideNumber', { slideNumber: i + 1 })}
            >
              <span className={styles.paginationButtonInner} />
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

LocationHeroImageCarousel.displayName = 'LocationHeroImageCarousel'

export default LocationHeroImageCarousel
