import React, { useState, useEffect, useRef, useImperativeHandle } from 'react'
import classnames from 'classnames'
import styles from './Select.module.scss'
import Icon from '@/components/Icon/Icon'

const Select = React.forwardRef<SelectRef, SelectProps>(
  ({ className, placeholder, value, onChange, onFocus, onBlur, items, ariaLabel }, ref) => {
    const [isFocused, setIsFocused] = useState(false)
    const selectRef = useRef<HTMLSelectElement>(null)
    const [selectClass, setSelectClass] = useState(false)

    useImperativeHandle(ref, () => ({
      ...selectRef.current,
      focus: () => {
        selectRef.current?.focus()
      },
    }))

    const handleFocus = (e: React.FocusEvent<HTMLSelectElement>) => {
      setIsFocused(true)
      if (onFocus) onFocus(e)
    }

    const handleBlur = (e: React.FocusEvent<HTMLSelectElement>) => {
      setIsFocused(false)
      if (onBlur) onBlur(e)
    }

    useEffect(() => {
      setSelectClass(!!value)
    }, [value])

    return (
      <div className={styles.Select}>
        <select
          ref={selectRef}
          className={classnames(
            { [styles.selectTextColor]: value && selectClass },
            styles.selectElement,
            className,
            {
              [styles.focused]: isFocused,
            },
            {
              [styles.hasValue]: value,
            },
          )}
          value={value}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          aria-label={ariaLabel}
        >
          {placeholder && <option value="">{placeholder}</option>}
          {Array.isArray(items) &&
            items.map(item => (
              <option
                key={item}
                value={item}
              >
                {item}
              </option>
            ))}
        </select>
        <Icon
          name="caretDown"
          className={styles.caretIcon}
        />
      </div>
    )
  },
)

Select.displayName = 'Select'

export default Select
