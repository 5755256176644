'use client'
import { useState } from 'react'
import Button from '@/components/Button/Button'
import Input from '@/components/Input/Input'
import styles from './EmailSignupInput.module.scss'
import useI18n from '@/hooks/use-i18n'
import FormResult from './FormResults/FormResults'
import { useParams } from 'next/navigation'
import classNames from 'classnames'
import useCurrentPage from '@/hooks/use-current-page'

const EmailSignupInput = ({ className, variant = 'emailSignupSection', inputId }: EmailSignupInputProps) => {
  const params = useParams()
  const { i18n } = useI18n()
  const { currentLanguage } = useCurrentPage()
  const [formSubmitSuccess, setFormSubmitSuccess] = useState(false)
  const [formSubmit, setFormSubmit] = useState(false)

  const [formData, setFormData] = useState({
    emailAddress: '',
    location: typeof params.restaurantSlug === 'string' ? params.restaurantSlug : params.restaurantSlug?.[0] ?? '',
    language: currentLanguage,
  })
  const [loading, setLoading] = useState(false)
  const [memberAlreadySubscribed, setMemberAlreadySubscribed] = useState(false)
  const [validationErrors, setValidationErrors] = useState<{ field: string; errorMessage: string }[]>([])
  const [isFocused, setIsFocused] = useState(false)

  const validate = (field: string, value: string) => {
    let isValid = true
    let errorMessage = ''

    setValidationErrors(prevErrors => prevErrors.filter(error => error.field !== field))

    if (field === 'emailAddress') {
      const emailRegex = /^[^\s@]+@[a-zA-Z]+(\.[a-zA-Z]{2,})+$/
      if (!emailRegex.test(value)) {
        isValid = false
        errorMessage = i18n('invalidEmailAddress') || ''
      }
    }

    if (!isValid) {
      setValidationErrors(prevErrors => [...prevErrors, { field, error: true, errorMessage }])
    } else {
      setValidationErrors([])
    }

    return isValid
  }

  const handleInputChange = (fieldName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setFormData(prevState => ({
      ...prevState,
      [fieldName]: value,
    }))
  }

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const emailIsValid = validate('emailAddress', formData['emailAddress'])

    if (!emailIsValid) {
      setFormSubmit(true)
      setFormSubmitSuccess(false)
      return
    }

    setLoading(true)
    try {
      const response = await fetch('/api/newsletter-sign-up', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })
      const data = await response.json()
      const memberExists = data?.message === 'Member Exists'
      setMemberAlreadySubscribed(memberExists)
      setFormSubmitSuccess(data?.success || memberExists)
    } catch (error) {
      setMemberAlreadySubscribed(false)
      setFormSubmitSuccess(false)
      console.error({ error })
    }

    setFormSubmit(true)
    setLoading(false)
  }

  const emailError = validationErrors.find(error => error.field === 'emailAddress')?.errorMessage

  return (
    <div
      className={classNames(className, styles.EmailSignupInput)}
      data-variant={variant}
    >
      <form
        onSubmit={e => onSubmit(e)}
        className={classNames(styles.formContainer, {
          [styles.focused]: isFocused,
        })}
        noValidate
        autoComplete="on"
        name="emailSignupForm"
      >
        <div className={styles.inputContainer}>
          <Input
            className={styles.emailInput}
            value={formData.emailAddress}
            onChange={handleInputChange('emailAddress')}
            placeholder={i18n('typeYourEmail')}
            id={inputId}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
        </div>
        <Button
          type="submit"
          primary
          icon="caretRight"
          label={loading ? i18n('pleaseWait') : i18n('signUp')}
          disabled={loading}
        />
      </form>
      <div className={styles.FormResults}>
        {emailError ? (
          <FormResult
            status="error"
            errorMessage={
              <>
                <p>{emailError}</p>
              </>
            }
          />
        ) : formSubmit && !loading ? (
          formSubmitSuccess ? (
            <FormResult
              variant={variant === 'simple' ? 'neutral' : 'default'}
              status="success"
              successMessage={
                memberAlreadySubscribed ? (
                  <>
                    <p>{i18n('emailAlreadySubscribed')}</p>
                  </>
                ) : (
                  <>
                    <p>{i18n('thankYou')}</p>
                  </>
                )
              }
            />
          ) : (
            <FormResult
              status="error"
              errorMessage={
                <>
                  <p>{i18n('somethingWentWrong')}</p>
                </>
              }
            />
          )
        ) : null}
      </div>
    </div>
  )
}

export default EmailSignupInput
