'use client'

import classnames from 'classnames'
import styles from './Timeline.module.scss'
import TimelineItem from '@/sections/Timeline/TimelineItem/TimelineItem'
import 'swiper/css'
import Swiper from 'swiper'
import { SwiperOptions } from 'swiper/types'
import { useEffect, useRef, useState } from 'react'
import useWindowResize from '@/hooks/use-window-resize'
import TimelineDatesCarousel from '@/sections/Timeline/TimelineDatesCarousel/TimelineDatesCarousel'
import useI18n from '@/hooks/use-i18n'
import Widont from '@/components/Widont/Widont'
import AnimateContainer from '@/components/AnimateContainer/AnimateContainer'
import OrderDropdown from '@/components/OrderDropdown/OrderDropdown'
import useBreakpoint from '@/hooks/use-breakpoint'

const Timeline = ({ className, title, items }: SanityTimeline) => {
  const carousel = useRef<Swiper | null>(null)
  const carouselRef = useRef<HTMLDivElement | null>(null)
  const resizeKey = useWindowResize()
  const [activeIndex, setActiveIndex] = useState(0)
  const [isInitialized, setIsInitialized] = useState(false)
  const { i18n } = useI18n()
  const { isMobile } = useBreakpoint()

  useEffect(() => {
    if (!carousel.current || !isInitialized) return
    carousel?.current?.slideTo(activeIndex)
  }, [activeIndex, isInitialized])

  useEffect(() => {
    if (!carouselRef.current) return

    if (carousel.current) {
      carousel.current.destroy()
      setIsInitialized(false)
    }

    const settings: SwiperOptions = {
      slidesPerView: 'auto',
      spaceBetween: 200,
      on: {
        init: function () {
          setIsInitialized(true)
        },
      },
    }

    carousel.current = new Swiper(carouselRef.current, settings)

    carousel.current.on('slideChange', function () {
      if (!carousel.current) return
      setActiveIndex(carousel.current?.activeIndex)
    })

    return () => {
      if (carousel.current) {
        carousel.current.destroy()
      }
    }
  }, [resizeKey])

  if (!title || !items?.length) return null

  return (
    <div className={classnames(styles.Timeline, className)}>
      <AnimateContainer
        animationType="fadeIn"
        className={styles.inner}
      >
        <div className={styles.header}>
          <h1 className={styles.title}>
            <Widont text={title} />
          </h1>
          <div className={styles.dropdownContainer}>
            <OrderDropdown
              className={styles.customDropdown}
              customButtonItems={items.map((item, i) => {
                return {
                  label: item.year,
                  callback: () => {
                    setActiveIndex(i)
                  },
                }
              })}
              dropdownPosition={isMobile ? 'bottomLeft' : 'bottomRight'}
              label={i18n('travelToDate', { date: items[activeIndex].year })}
            />
          </div>
        </div>
        <div
          className={styles.carouselContainer}
          ref={carouselRef}
        >
          <ul className={classnames('swiper-wrapper', styles.listWrapper)}>
            {items.map((item, i) => (
              <li
                key={i}
                className={classnames('swiper-slide', styles.listWrapper__item, {
                  [styles.noImage]: !item?.image?.asset?.url,
                })}
              >
                <TimelineItem
                  {...item}
                  className={styles.listWrapper__itemComponent}
                  index={i}
                  setActiveIndex={setActiveIndex}
                />
              </li>
            ))}
          </ul>
        </div>
        <TimelineDatesCarousel
          setActiveIndex={setActiveIndex}
          activeIndex={activeIndex}
          items={items}
        />
      </AnimateContainer>
      <div className={styles.progressBarContainer}>
        <div
          className={styles.progress}
          style={{ width: `${((activeIndex + 1) / items.length) * 100}%` }}
        />
      </div>
    </div>
  )
}

Timeline.displayName = 'Timeline'

export default Timeline
