import React, { useState, useRef, useImperativeHandle, useEffect } from 'react'
import classnames from 'classnames'
import styles from './Input.module.scss'

const Input = React.forwardRef<InputTextRef, InputTextProps>(
  ({ className, placeholder, value, onChange, onFocus, onBlur, type = 'text', ariaLabel, id }, ref) => {
    const [isFocused, setIsFocused] = useState(false)
    const inputRef = useRef<HTMLInputElement>(null)
    const [placeholderValue, setPlaceholder] = useState(placeholder)
    const [inputClass, setInputClass] = useState(false)

    useImperativeHandle(ref, () => ({
      ...inputRef.current,
      focus: () => {
        inputRef.current?.focus() ?? ''
      },
    }))

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      setIsFocused(true)
      setPlaceholder('')
      if (onFocus) onFocus(e)
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      setIsFocused(false)
      setPlaceholder(placeholder)
      if (onBlur) onBlur(e)
    }

    useEffect(() => {
      setInputClass(!!value)
    }, [value])

    return (
      <input
        ref={inputRef}
        className={classnames(inputClass && styles.inputTextColor, styles.InputText, className, {
          [styles.focused]: isFocused,
        })}
        id={id}
        placeholder={placeholderValue}
        value={value}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        type={type}
        aria-label={ariaLabel}
      />
    )
  },
)

Input.displayName = 'Input'

export default Input
