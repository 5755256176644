'use client'

import classnames from 'classnames'
import styles from './AnimateContainer.module.scss'
import { AriaAttributes, DOMAttributes, useEffect, useRef } from 'react'
import useInView from '@/hooks/use-in-view'
import gsap from 'gsap'
import useBreakpoint from '@/hooks/use-breakpoint'

interface AnimateContainerProps<T> extends AriaAttributes, DOMAttributes<T> {
  custom?: string
  className?: string
  children: React.ReactNode
  animationType: 'fadeIn' | 'fadeInUp'
  element?: 'div' | 'article' | 'ul' | 'h1' | 'li'
  delay?: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  style?: any
  scrolltriggerStart?: string
  setImmediate?: boolean
}

const AnimateContainer = ({
  className,
  children,
  animationType,
  element,
  delay,
  style,
  scrolltriggerStart,
  setImmediate,
}: AnimateContainerProps<'div'>) => {
  const containerRef = useRef<HTMLElement | null>(null)
  const { isMobile } = useBreakpoint()
  const { isInView, setElementToObserve } = useInView({
    fireOnce: true,
    scrolltriggerStart: scrolltriggerStart || isMobile ? 'top bottom' : 'top+=100 bottom',
  })
  const fired = useRef(false)
  const Element = element || 'div'

  useEffect(() => {
    if (!containerRef.current || !isInView || setImmediate) return

    const animType = animationType || 'fadeIn'

    const ctx = gsap.context(() => {
      gsap.killTweensOf(containerRef.current)
      if (isInView && !fired.current) {
        fired.current = true

        let optionsFrom = {}
        let optionsTo = {}
        const duration = 0.8
        const ease = 'Power4.easeOut'

        if (animType === 'fadeIn') {
          optionsFrom = {
            opacity: 0.001,
          }
          optionsTo = {
            opacity: 1,
            duration,
            ease,
            delay,
          }
        }

        if (animType === 'fadeInUp') {
          optionsFrom = {
            opacity: 0.001,
            y: 100,
          }
          optionsTo = {
            opacity: 1,
            y: 0,
            duration,
            ease,
            delay,
          }
        }

        gsap.fromTo(containerRef.current, optionsFrom, optionsTo)
      }
    }, containerRef.current)

    return () => {
      ctx.revert()
    }
  }, [isInView, animationType, delay, setImmediate])

  return (
    <Element
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ref={(ref: any) => {
        setElementToObserve(ref)
        containerRef.current = ref
      }}
      className={classnames(styles.AnimateContainer, className, { [styles.setImmediate]: setImmediate })}
      style={style}
    >
      {children}
    </Element>
  )
}

AnimateContainer.displayName = 'AnimateContainer'

export default AnimateContainer
