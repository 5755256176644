'use client'

import classnames from 'classnames'
import styles from './StaggeredImages.module.scss'
import StaggeredImagesComponent from '@/components/StaggeredImages/StaggeredImages'

const StaggeredImages = ({ className, items }: SanityStaggeredImages) => {
  if (!items?.length) return null

  return (
    <div className={classnames(styles.StaggeredImages, className)}>
      <div className={styles.inner}>
        <StaggeredImagesComponent items={items} />
      </div>
    </div>
  )
}

StaggeredImages.displayName = 'StaggeredImages'

export default StaggeredImages
