'use client'

import { useContext } from 'react'
import classNames from 'classnames'
import styles from './Footer.module.scss'
import { GlobalSettingsContext } from '@/context/GlobalSettings'
import Icon from '@/components/Icon/Icon'
import Link from '@/components/Link/Link'
import FooterLanguageSwitcher from '@/components/Footer/FooterLanguageSwitcher/FooterLanguageSwitcher'
import AnimateContainer from '@/components/AnimateContainer/AnimateContainer'
import useCurrentPage from '@/hooks/use-current-page'
import { DOC_TYPES } from '@/data'

const Footer = () => {
  const { footerData } = useContext(GlobalSettingsContext)
  const { currentDocType, currentSlug } = useCurrentPage()

  if (!footerData?.linkColumns?.length || !footerData?.legal) return null

  return (
    <footer
      className={styles.Footer}
      data-extra-padding={currentDocType === DOC_TYPES.LOCATION}
    >
      <AnimateContainer
        animationType="fadeIn"
        className={styles.inner}
      >
        <div className={styles.top}>
          <div className={styles.leftSide}>
            <Icon
              name="footerSymbol"
              className={styles.footerSymbol}
            />
          </div>
          <div className={styles.rightSide}>
            {footerData?.linkColumns.map((column, i) => {
              return (
                <div
                  className={styles.column}
                  key={i}
                >
                  <p className={styles.columnTitle}>{column.title}</p>
                  <ul className={styles.columnList}>
                    {column.items.map((item, i) => {
                      const isActiveLink =
                        typeof item.link !== 'string' && 'slug' in item.link && currentSlug === item.link.slug

                      return (
                        <>
                          <li
                            className={styles.columnList__item}
                            key={i}
                          >
                            <Link
                              className={classNames(
                                styles.columnList__link,
                                isActiveLink && styles.columnList__link__active,
                              )}
                              link={item}
                            />
                          </li>
                          {item?.link?.toString().includes('privacy-policy') && (
                            <li className={styles.columnList__item}>
                              <a
                                className={classNames(
                                  styles.columnList__link,
                                  isActiveLink && styles.columnList__link__active,
                                  'truevault-polaris-privacy-notice',
                                )}
                                href="https://privacy.dtf.com/privacy-policy#california-privacy-notice"
                                hidden
                              >
                                California Privacy Notice
                              </a>
                            </li>
                          )}
                        </>
                      )
                    })}
                  </ul>
                </div>
              )
            })}
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.languageSwitcherContainer}>
            <FooterLanguageSwitcher />
          </div>
          <ul className={styles.socialMediaLinks}>
            {footerData?.socialMediaLinks?.map((item, i) => (
              <li
                key={i}
                className={styles.socialMediaLinks__item}
              >
                <Link
                  link={item}
                  className={styles.socialMediaLinks__link}
                />
              </li>
            ))}
          </ul>
          <div className={styles.legalContainer}>
            <p className={styles.legalText}>{footerData.legal}</p>
          </div>
        </div>
      </AnimateContainer>
    </footer>
  )
}

Footer.displayName = 'Footer'

export default Footer
