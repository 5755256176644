import { useCallback, useRef } from 'react'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import gsap from 'gsap'
import useBreakpoint from '@/hooks/use-breakpoint'
import useWindowResize from '@/hooks/use-window-resize'

ScrollTrigger.config({
  ignoreMobileResize: true,
})

gsap.registerPlugin(ScrollTrigger)

const UseStaggeredImagesParallax = () => {
  const scrollTriggerRef = useRef<ScrollTrigger | null>()
  const timelineRef = useRef<GSAPTimeline | null>(null)
  const { isMobile } = useBreakpoint()
  const resizeKey = useWindowResize()

  const destroyParallax = ({ element }: { element: HTMLElement }) => {
    gsap.killTweensOf(element)
    gsap.set(element, { clearProps: 'all' })
    if (scrollTriggerRef.current) {
      scrollTriggerRef.current.kill()
    }
    if (timelineRef.current) {
      timelineRef.current.kill()
    }
  }

  const setParallax = useCallback(
    ({ element }: { element: HTMLElement }) => {
      destroyParallax({ element })

      if (isMobile) return

      timelineRef.current = gsap.timeline()

      const marginTop = parseInt(window.getComputedStyle(element).marginTop)

      timelineRef.current.fromTo(
        element,
        {
          y: -marginTop,
        },
        {
          y: 0,
        },
      )

      scrollTriggerRef.current = new ScrollTrigger({
        trigger: element,
        start: 'top bottom',
        end: 'top top',
        animation: timelineRef.current,
        scrub: true,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMobile, resizeKey],
  )

  return { setParallax, destroyParallax }
}

UseStaggeredImagesParallax.displayName = 'UseStaggeredImagesParallax'

export default UseStaggeredImagesParallax
