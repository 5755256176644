import React from 'react'
import styles from './ContactFormFieldError.module.scss'

interface ContactFormFieldErrorProps {
  message?: React.ReactNode
}

const ContactFormFieldError = ({ message }: ContactFormFieldErrorProps) => {
  return message ? <div className={styles.ContactFormFieldError}>{message}</div> : null
}

export default ContactFormFieldError
