'use client'

import classnames from 'classnames'
import styles from './EcommerceItemList.module.scss'
import EcommerceItemCard from '@/components/EcommerceItemCard/EcommerceItemCard'

const EcommerceItemList = ({ className, items }: SanityEcommerceItemList) => {
  if (!items?.length) return null

  return (
    <div className={classnames(styles.EcommerceItemList, className)}>
      <div className={styles.inner}>
        <ul className={styles.grid}>
          {items.map((item, i) => (
            <li
              key={i}
              className={styles.item}
            >
              <EcommerceItemCard {...item} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

EcommerceItemList.displayName = 'EcommerceItemList'

export default EcommerceItemList
