'use client'

import 'swiper/css'
import Swiper from 'swiper'
import { SwiperOptions } from 'swiper/types'
import classnames from 'classnames'
import styles from './MerchandiseShowcase.module.scss'
import MerchandiseShowcaseItem from '@/sections/MerchandiseShowcase/MerchandiseShowcaseItem/MerchandiseShowcaseItem'
import { useEffect, useRef, useState } from 'react'
import useWindowResize from '@/hooks/use-window-resize'
import Icon from '@/components/Icon/Icon'
import useBreakpoint from '@/hooks/use-breakpoint'
import AnimateContainer from '@/components/AnimateContainer/AnimateContainer'

const MerchandiseShowcase = ({ className, title, items }: SanityMerchandiseShowcase) => {
  const resizeKey = useWindowResize()
  const carousel = useRef<Swiper | null>(null)
  const carouselRef = useRef<HTMLDivElement | null>(null)
  const [allowedPagination, setAllowedPagination] = useState<{ prev: boolean; next: boolean }>({
    prev: false,
    next: false,
  })
  const { breakpoint } = useBreakpoint()

  let perView = 3

  if (breakpoint?.name === 'mobile') {
    perView = 1
  }

  if (breakpoint?.name === 'tablet') {
    perView = 2
  }

  useEffect(() => {
    if (!carouselRef.current) return

    if (carousel.current) {
      carousel.current.destroy()
    }

    const settings: SwiperOptions = {
      slidesPerView: perView,
      spaceBetween: 21,
    }

    carousel.current = new Swiper(carouselRef.current, settings)

    const detectAllowPagination = () => {
      if (!carousel.current) return
      if (carousel.current.activeIndex === 0) {
        setAllowedPagination({ prev: false, next: true })
      } else if (carousel.current.activeIndex === items.length - perView) {
        setAllowedPagination({ prev: true, next: false })
      } else {
        setAllowedPagination({ prev: true, next: true })
      }
    }

    detectAllowPagination()

    carousel.current.on('slideChange', function () {
      detectAllowPagination()
    })

    return () => {
      if (carousel.current) {
        carousel.current.destroy()
      }
    }
  }, [resizeKey, items, perView])

  if (!title) return null

  return (
    <div
      className={classnames(styles.MerchandiseShowcase, className)}
      data-allowed-prev={allowedPagination.prev}
      data-allowed-next={allowedPagination.next}
    >
      <AnimateContainer
        animationType="fadeIn"
        className={styles.inner}
      >
        <div className={styles.header}>
          <h1 className={styles.title}>{title}</h1>
          {items.length > perView && (
            <div className={styles.paginationContainer}>
              <button
                onClick={() => {
                  if (!allowedPagination.prev) return
                  carousel.current?.slidePrev()
                }}
                className={classnames(styles.button, styles.prev)}
                aria-label="Previous"
              >
                <Icon
                  className={styles.icon}
                  name="caretRight"
                />
              </button>
              <button
                className={classnames(styles.button, styles.next)}
                onClick={() => {
                  if (!allowedPagination.next) return
                  carousel.current?.slideNext()
                }}
                aria-label="Next"
              >
                <Icon
                  className={styles.icon}
                  name="caretRight"
                />
              </button>
            </div>
          )}
        </div>
        <div
          className={styles.carousel}
          ref={carouselRef}
        >
          <ul className={classnames(styles.carouselInner, 'swiper-wrapper')}>
            {items.map((item, i) => (
              <li
                className={classnames(styles.carouselItem, 'swiper-slide')}
                key={i}
              >
                <MerchandiseShowcaseItem {...item} />
              </li>
            ))}
          </ul>
        </div>
      </AnimateContainer>
    </div>
  )
}

MerchandiseShowcase.displayName = 'MerchandiseShowcase'

export default MerchandiseShowcase
