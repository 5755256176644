'use client'

import classnames from 'classnames'
import styles from './HomepageHero.module.scss'
import Icon from '@/components/Icon/Icon'
import { CSSProperties, useContext, useEffect, useRef } from 'react'
import VideoLoop from '@/components/VideoLoop/VideoLoop'
import SanityImage from '@/components/SanityImage/SanityImage'
import Button from '@/components/Button/Button'
import gsap from 'gsap'
import useWindowResize from '@/hooks/use-window-resize'
import Widont from '@/components/Widont/Widont'
import { ScrollContext } from '@/context/Scroll'
import useI18n from '@/hooks/use-i18n'

const HomepageHero = ({
  className,
  subtitle,
  primaryCta,
  secondaryCta,
  mediaType,
  overlayOpacity,
  image,
  videoLoopDesktop,
  videoLoopMobile,
}: SanityHomepageHero) => {
  const arrowContainerRef = useRef<HTMLDivElement | null>(null)
  const arrowTimelineRef = useRef<GSAPTimeline | null>(null)
  const resizeKey = useWindowResize()
  const containerRef = useRef<HTMLDivElement | null>(null)
  const { scroll } = useContext(ScrollContext)
  const { i18n } = useI18n()

  const handleScrollIndicatorClick = () => {
    if (containerRef.current && scroll) {
      const height = containerRef.current.offsetHeight
      scroll?.scrollTo(height, { duration: 0.6 })
    }
  }

  useEffect(() => {
    if (!arrowContainerRef.current) return

    if (arrowTimelineRef.current) {
      arrowTimelineRef.current.kill()
    }

    arrowTimelineRef.current = gsap.timeline({
      repeat: -1,
    })

    arrowTimelineRef.current.fromTo(
      arrowContainerRef.current,
      {
        y: 0,
        autoAlpha: 1,
      },
      {
        y: '130%',
        autoAlpha: 0,
        duration: 1.5,
        ease: 'Power3.easeIn',
      },
    )

    arrowTimelineRef.current.fromTo(
      arrowContainerRef.current,
      {
        y: '-130%',
        autoAlpha: 0,
      },
      {
        y: 0,
        autoAlpha: 1,
        duration: 1.5,
        ease: 'Power3.easeOut',
      },
    )
  }, [resizeKey])

  return (
    <div
      className={classnames(styles.HomepageHero, className)}
      style={
        {
          '--overlay-opacity': overlayOpacity / 100,
        } as CSSProperties
      }
      ref={containerRef}
    >
      <div className={styles.dtfLogoContainer}>
        <Icon
          className={styles.dtfLogo}
          name="dtf"
        />
      </div>
      <div className={styles.inner}>
        <div className={styles.lowerContent}>
          {(primaryCta || secondaryCta) && (
            <div className={styles.buttons}>
              {primaryCta && (
                <Button
                  link={primaryCta}
                  className={styles.primaryCta}
                  primary
                />
              )}
              {secondaryCta && (
                <Button
                  link={secondaryCta}
                  className={styles.secondaryCta}
                />
              )}
            </div>
          )}
          {subtitle && (
            <p className={styles.subtitle}>
              <Widont text={subtitle} />
            </p>
          )}
          <button
            className={styles.scrollIndicator}
            onClick={handleScrollIndicatorClick}
            aria-label={i18n('scrollToNextSection')}
          >
            <div
              className={styles.scrollIndicator__iconContainer}
              ref={arrowContainerRef}
            >
              <Icon
                name="caretDown"
                className={styles.scrollIndicator__icon}
              />
            </div>
          </button>
        </div>
      </div>
      <div className={styles.overlay} />
      <div className={styles.mediaContainer}>
        {mediaType === 'image' && image && (
          <SanityImage
            image={image}
            className={styles.image}
            breakpoints={{
              xs: {
                width: 700,
                image,
                options: {
                  height: 1400,
                  fit: 'crop',
                },
              },
              tablet: {
                width: 2000,
                image,
              },
              desktop: {
                width: 2500,
                image,
              },
            }}
          />
        )}
        {mediaType === 'video' && videoLoopMobile && videoLoopDesktop && (
          <VideoLoop
            desktopLoop={videoLoopDesktop}
            mobileLoop={videoLoopMobile}
            desktopSizeMb={3}
            mobileSizeMb={1}
            className={styles.video}
          />
        )}
      </div>
    </div>
  )
}

HomepageHero.displayName = 'HomepageHero'

export default HomepageHero
