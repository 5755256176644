'use client'

import { useState, useEffect } from 'react'
import classnames from 'classnames'
import styles from './GiftCardIframe.module.scss'
import Icon from '@/components/Icon/Icon'

const GiftCardIframe = ({ className }: SanityGiftCardIframe) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false)
    }, 5000)

    return () => clearTimeout(timer)
  }, [])

  return (
    <div className={classnames(styles.GiftCardIframe, className)}>
      <div className={styles.inner}>
        {loading && (
          <div className={styles.spinner}>
            <Icon name={'spinner'} />
          </div>
        )}
        <iframe
          className={classnames(styles.iframe, { [styles.hidden]: loading })}
          id="buyatab-frame"
          src="https://www.buyatab.com/gcp/?id=5632#customize"
          allow="payment"
          scrolling="no"
        />
      </div>
    </div>
  )
}

GiftCardIframe.displayName = 'GiftCardIframe'

export default GiftCardIframe
