'use client'

import classnames from 'classnames'
import styles from './LocationsListItem.module.scss'
import Link from '@/components/Link/Link'
import RestaurantOpenClosedBlurb from '@/components/RestaurantOpenClosedBlurb/RestaurantOpenClosedBlurb'
import useI18n from '@/hooks/use-i18n'
import { getPrivateDiningId, getRestaurantAddressString } from '@/utils'
import OrderDropdown from '@/components/OrderDropdown/OrderDropdown'
import AnimateContainer from '@/components/AnimateContainer/AnimateContainer'
import Button from '@/components/Button/Button'
import { DOC_TYPES } from '@/data'
import useCurrentPage from '@/hooks/use-current-page'
import { LOCATION_HERO_INPUT_ID } from '@/sections/LocationHero/LocationHero'
import useBreakpoint from '@/hooks/use-breakpoint'
import Icon from '@/components/Icon/Icon'

type LocationsListItem = SanityPage & {
  className?: string
  showPrivateDiningOnly: boolean
  index: number
  totalItems: number
}

const LocationsListItem = ({
  className,
  slug,
  title,
  locationData,
  showPrivateDiningOnly,
  index,
  totalItems,
}: LocationsListItem) => {
  const hoursFormatted = typeof locationData?.hours === 'string' ? JSON.parse(locationData?.hours) : locationData?.hours
  const { i18n } = useI18n()
  const { currentLanguage } = useCurrentPage()
  const { isMobile } = useBreakpoint()
  const addressString = getRestaurantAddressString({
    streetAddress: locationData?.streetAddress,
    city: locationData?.city,
    state: i18n(locationData?.state as string),
    postalCode: locationData?.postalCode,
  })
  const locationLink = {
    label: '',
    linkType: 'internal',
    link: {
      _type: DOC_TYPES.LOCATION,
      slug: slug.current,
    },
  }

  const areaTitle = (
    <span className={styles.areaTitle}>
      <span className={styles.areaTitle__at}>&nbsp;{i18n('at')}</span>&nbsp;{locationData?.areaTitle}
    </span>
  )

  if (!locationData || !title || !slug?.current) return null

  return (
    <AnimateContainer
      element="li"
      animationType="fadeInUp"
      className={classnames(styles.LocationsListItem, className, { [styles.isComingSoon]: locationData?.isComingSoon })}
      style={{ zIndex: totalItems - index }}
      setImmediate={isMobile === true}
    >
      <Link
        className={styles.textSide}
        link={locationLink as SanityLink}
      >
        <div className={styles.titlesContainer}>
          <h1 className={styles.title}>
            {title} {locationData?.areaTitle && <span className={styles.areaTitleContainerMobile}>{areaTitle}</span>}
            <span className={styles.hoverRightIcon}>
              <Icon
                name="caretRight"
                className={styles.hoverRightIcon__icon}
              />
            </span>
          </h1>
          {locationData?.areaTitle && <p className={styles.areaTitleContainerDesktop}>{areaTitle}</p>}
          <div className={styles.openCloseBlurbContainerMobile}>
            <RestaurantOpenClosedBlurb
              className={styles.openClosedBlurb}
              hours={hoursFormatted}
              timeZone={locationData?.timeZone}
              holidayHours={locationData.holidayHours}
              isComingSoon={locationData?.isComingSoon}
            />
          </div>
        </div>
        <div className={styles.phoneNumberAddressContainer}>
          {locationData?.phoneNumber && (
            <p className={styles.phoneNumber}>{i18n('phoneNumberShort', { phoneNumber: locationData?.phoneNumber })}</p>
          )}
          <p className={styles.address}>
            {addressString
              ? i18n('addressShort', {
                  address: addressString,
                })
              : i18n('newLocationOpeningSoon')}
          </p>
        </div>
      </Link>
      <div className={styles.blurbColumn}>
        <div className={styles.openCloseBlurbContainerDesktop}>
          <RestaurantOpenClosedBlurb
            className={styles.openClosedBlurb}
            hours={hoursFormatted}
            timeZone={locationData?.timeZone}
            holidayHours={locationData.holidayHours}
            isComingSoon={locationData?.isComingSoon}
          />
        </div>
      </div>
      <div className={styles.ctaSide}>
        {locationData?.isComingSoon ? (
          <Button
            className={styles.comingSoonButton}
            label={i18n('notifyMeWhenOpening')}
            link={locationLink as SanityLink}
            secondary
            icon="caretRight"
            onClick={() => {
              setTimeout(() => {
                const input = document.getElementById(LOCATION_HERO_INPUT_ID)
                if (input) input.focus()
              }, 150)
            }}
          />
        ) : (
          <>
            <div className={styles.buttons}>
              {showPrivateDiningOnly && (
                <Button
                  primary
                  link={
                    {
                      label: i18n('details'),
                      linkType: 'internal',
                      link: {
                        _type: DOC_TYPES.LOCATION,
                        slug: slug.current,
                      },
                      hash: getPrivateDiningId(currentLanguage as string, slug.current),
                    } as SanityLink
                  }
                  icon="caretRight"
                />
              )}
              {locationData?.reservationLinks?.length && !showPrivateDiningOnly && (
                <OrderDropdown
                  className={styles.reserveButton}
                  serviceLinks={locationData?.reservationLinks}
                  dropdownPosition="bottomRight"
                  isReserve
                  primary
                  slug={slug?.current}
                />
              )}
              {/*
                THIS is purely to keep the spacing consistent if there are
                no service links present.
              */}
              {!locationData?.serviceLinks?.length && (
                <Button
                  label={i18n('order')}
                  icon="caretDown"
                  className={styles.disabledOrderButtonPlacement}
                  disabled
                />
              )}
              {locationData?.serviceLinks?.length && (
                <OrderDropdown
                  className={styles.orderButton}
                  serviceLinks={locationData?.serviceLinks}
                  dropdownPosition="bottomRight"
                />
              )}
            </div>
          </>
        )}
      </div>
    </AnimateContainer>
  )
}

LocationsListItem.displayName = 'LocationsListItem'

export default LocationsListItem
