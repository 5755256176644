import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import { getLocationListItemId, getRestaurantAddressString } from '@/utils'
import styles from './LocationHero.module.scss'
import useI18n from '@/hooks/use-i18n'
import { DOC_TYPES } from '@/data'
import Button from '@/components/Button/Button'
import LocationHeroHours from '@/sections/LocationHero/LocationHeroHours/LocationHeroHours'
import OrderDropdown from '@/components/OrderDropdown/OrderDropdown'
import { useEffect } from 'react'
import useStore from '@/store'
import EmailSignupInput from '@/components/EmailSignupInput/EmailSignupInput'
import useBreakpoint from '@/hooks/use-breakpoint'
import LocationHeroImageCarousel from '@/sections/LocationHero/LocationHeroImageCarousel/LocationHeroImageCarousel'

export const LOCATION_HERO_INPUT_ID = 'locationHeroInputNewsletter'

const LocationHero = (props: LocationHero) => {
  const {
    areaTitle,
    city,
    featuredImage,
    googleMapsLink,
    holidayHours,
    hasPrivateDining,
    hours,
    images,
    isComingSoon,
    phoneNumber,
    postalCode,
    reservationLinks,
    serviceLinks,
    state,
    streetAddress,
    title,
    timeZone,
  } = props
  const { i18n } = useI18n()
  const setActiveRestaurant = useStore(state => state.setActiveRestaurant)
  const { isMobile } = useBreakpoint()
  const addressString = getRestaurantAddressString({
    streetAddress,
    city,
    state,
    postalCode,
  })
  const breadcrumbLinks: SanityLink[] = [
    {
      label: i18n('all'),
      linkType: 'internal',
      link: {
        _type: DOC_TYPES.PAGE,
        slug: 'locations',
      },
    },
    {
      label: i18n(state as string),
      linkType: 'internal',
      link: {
        _type: DOC_TYPES.PAGE,
        slug: 'locations',
      },
      hash: getLocationListItemId(state as string),
    },
  ]

  useEffect(() => {
    setActiveRestaurant(props)
  }, [setActiveRestaurant, props])

  if (!title) return null

  return (
    <div className={styles.LocationHero}>
      <div className={styles.inner}>
        <div className={styles.textSide}>
          <Breadcrumbs
            links={breadcrumbLinks}
            className={styles.breadcrumbs}
          />
          {isComingSoon && <p className={styles.comingSoonText}>{i18n('comingSoon')}</p>}
          <h1 className={styles.title}>{title}</h1>
          {areaTitle && (
            <p className={styles.areaTitle}>
              <span className={styles.areaTitle__at}>{i18n('at')}&nbsp;</span>
              <span className={styles.areaTitle__title}>{areaTitle}</span>
            </p>
          )}
          {isComingSoon && (
            <div className={styles.emailSignupContainer}>
              <p className={styles.emailSignupContainer__text}>
                <span>{i18n('beTheFirstToDine')}</span>
                <span>
                  <strong>&nbsp;{i18n('enterEmailBelow')}</strong>
                </span>
              </p>
              <EmailSignupInput
                className={styles.emailSignupContainer__emailInput}
                variant="simple"
                inputId={LOCATION_HERO_INPUT_ID}
              />
            </div>
          )}
          {!isComingSoon && (
            <div className={styles.buttons}>
              {reservationLinks && reservationLinks?.length > 0 && (
                <OrderDropdown
                  serviceLinks={reservationLinks}
                  primary
                  isReserve
                  dropdownPosition={'bottomLeft'}
                />
              )}
              {serviceLinks && serviceLinks?.length > 0 && (
                <OrderDropdown
                  serviceLinks={serviceLinks}
                  dropdownPosition={isMobile ? 'bottomRight' : 'bottomLeft'}
                />
              )}
            </div>
          )}
          {hasPrivateDining && (
            <div className={styles.reservationOnly}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="10"
                viewBox="0 0 9 10"
                fill="none"
              >
                <path
                  d="M1.71571 9.28743C1.28273 8.9786 1.14743 8.36096 1.58041 7.79946C2.09457 7.15374 2.87934 6.64839 4.15121 5.4131C2.68991 5.35695 1.30979 5.27273 0.822695 5.13235C0.0920461 4.93583 -0.124443 4.4024 0.064985 3.81283C0.20029 3.25134 0.876817 2.97059 1.4451 3.22326C2.09457 3.44786 2.82522 4.03743 4.25945 4.93583C3.82647 3.19519 3.50174 2.40909 3.47468 1.56684C3.42056 0.893048 3.93472 0.5 4.503 0.5C5.07128 0.5 5.58545 0.893048 5.53132 1.56684C5.50426 2.40909 5.17953 3.25133 4.74655 4.93583C6.01842 4.14973 7.12793 3.39171 7.53384 3.19519C8.21037 2.88636 8.77865 3.25134 8.94102 3.81283C9.13045 4.43048 8.85984 4.93583 8.18331 5.10427C7.66915 5.24465 6.31609 5.32887 4.88186 5.4131C6.07255 6.42379 7.23617 7.37834 7.45266 7.74331C7.88564 8.38903 7.66915 8.9786 7.20911 9.25935C6.83026 9.51203 6.12667 9.56818 5.74781 8.89438C5.50426 8.50133 5.04422 7.32219 4.503 5.72192C3.93472 7.40641 3.50174 8.61363 3.25819 9.00668C2.87934 9.5401 2.20281 9.6524 1.71571 9.28743Z"
                  fill="#D7000F"
                />
              </svg>
              <span>{i18n('reservationOnly')}</span>
            </div>
          )}
          <div className={styles.contactMethodsContainer}>
            <div className={styles.contactMethodsContainer__left}>
              {phoneNumber && <p className={styles.phoneNumber}>{i18n('phoneNumberShort', { phoneNumber })}</p>}
              {addressString && (
                <p className={styles.address}>
                  {i18n('addressShort', {
                    address: addressString,
                  })}
                </p>
              )}
            </div>
            <div className={styles.contactMethodsContainer__right}>
              {googleMapsLink && (
                <Button
                  link={{
                    label: i18n('findUs'),
                    linkType: 'external',
                    link: googleMapsLink,
                  }}
                  plain
                  icon="caretRight"
                />
              )}
            </div>
          </div>
          {!isComingSoon && (
            <div className={styles.hoursContainer}>
              {hours && timeZone && (
                <LocationHeroHours
                  hours={hours}
                  timeZone={timeZone}
                  holidayHours={holidayHours}
                />
              )}
            </div>
          )}
        </div>
        <div className={styles.imageSide}>
          {featuredImage && <LocationHeroImageCarousel images={[featuredImage, ...(images as SanityImage[])]} />}
        </div>
      </div>
    </div>
  )
}

LocationHero.displayName = 'LocationHero'

export default LocationHero
