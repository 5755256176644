import { PortableText, PortableTextReactComponents } from '@portabletext/react'
import Link from '@/components/Link/Link'

const portableTextSerializer: Partial<PortableTextReactComponents> = {
  types: {
    link: ({ value }) => {
      return <Link link={value} />
    },
  },
}

function BlogPostRichText({ content }: SanityContentBlockProps) {
  return (
    <PortableText
      value={content}
      components={portableTextSerializer}
      onMissingComponent={false}
    />
  )
}

export default BlogPostRichText
