'use client'

import classnames from 'classnames'
import styles from './HeaderWithCtas.module.scss'
import HeaderWithCtasRichText from '@/components/HeaderWithCtas/HeaderWithCtasRichText'
import Button from '@/components/Button/Button'
import EmailSignupInput from '@/components/EmailSignupInput/EmailSignupInput'

const HeaderWithCtas = ({
  className,
  headingContent,
  primaryCta,
  secondaryCta,
  alignItems,
  hasNewsletterSignup,
  desktopTitleSize,
  mobileTitleSize,
}: HeaderWithCtasProps) => {
  if (!headingContent) return null

  return (
    <div
      className={classnames(styles.HeaderWithCtas, className)}
      data-align-items={alignItems}
      data-desktop-title-size={desktopTitleSize}
      data-mobile-title-size={mobileTitleSize}
    >
      <div className={styles.portableText}>
        <HeaderWithCtasRichText content={headingContent} />
      </div>
      {(primaryCta || secondaryCta) && !hasNewsletterSignup && (
        <div className={styles.buttons}>
          {primaryCta && (
            <Button
              link={primaryCta}
              primary
            />
          )}
          {secondaryCta && (
            <Button
              link={secondaryCta}
              secondary
            />
          )}
        </div>
      )}
      {hasNewsletterSignup && (
        <EmailSignupInput
          className={styles.emailSignupInput}
          variant="simple"
        />
      )}
    </div>
  )
}

HeaderWithCtas.displayName = 'HeaderWithCtas'

export default HeaderWithCtas
