import classNames from 'classnames'

import styles from './DateInput.module.scss'

import contactFormStyles from '@/components/ContactForm/ContactForm.module.scss'

import { InputHTMLAttributes } from 'react'

interface DateInputProps extends InputHTMLAttributes<HTMLInputElement> {
  errorMessage?: string
}

const DateInput = ({ errorMessage, ...inputProps }: DateInputProps) => {
  return (
    <input
      {...inputProps}
      type="date"
      className={classNames(styles.dateInputReset, contactFormStyles.formInputText, styles.dateInput, {
        [contactFormStyles.formInputTextError]: errorMessage,
      })}
    />
  )
}

export default DateInput
