'use client'

import classnames from 'classnames'
import styles from './ContentCards.module.scss'
import SanityImage from '@/components/SanityImage/SanityImage'
import Button from '@/components/Button/Button'
import Widont from '@/components/Widont/Widont'
import VideoLoop from '@/components/VideoLoop/VideoLoop'
import AnimateContainer from '@/components/AnimateContainer/AnimateContainer'

const ContentCards = ({ className, items }: SanityContentCards) => {
  if (!items?.length) return null

  return (
    <div className={classnames(styles.ContentCards, className)}>
      <div className={styles.inner}>
        <ul className={styles.list}>
          {items.map((item, i) => (
            <AnimateContainer
              element="li"
              animationType="fadeInUp"
              className={styles.item}
              delay={i * 0.3}
              key={i}
            >
              <article className={styles.itemInner}>
                <div className={styles.imageContainer}>
                  {item.mediaType === 'image' && item?.image && (
                    <SanityImage
                      image={item.image}
                      className={styles.image}
                      breakpoints={{
                        tablet: {
                          width: 1100,
                          image: item.image,
                        },
                        xs: {
                          width: 800,
                          image: item.image,
                        },
                      }}
                    />
                  )}
                  {item.mediaType === 'video' && item.videoLoopMobile && item.videoLoopDesktop && (
                    <VideoLoop
                      desktopLoop={item.videoLoopDesktop}
                      mobileLoop={item.videoLoopMobile}
                      desktopSizeMb={3}
                      mobileSizeMb={0.75}
                      className={styles.video}
                    />
                  )}
                </div>
                <div className={styles.textContent}>
                  <h1 className={styles.title}>
                    <Widont text={item.title} />
                  </h1>
                  {item.description && (
                    <p className={styles.description}>
                      <Widont text={item.description} />
                    </p>
                  )}
                  {item.cta && (
                    <div className={styles.ctaContainer}>
                      <Button
                        link={item.cta}
                        className={styles.cta}
                      />
                      <p className={styles.smallText}>
                        {item.smallText ? <Widont text={item.smallText} /> : <span>&nbsp;</span>}
                      </p>
                    </div>
                  )}
                </div>
              </article>
            </AnimateContainer>
          ))}
        </ul>
      </div>
    </div>
  )
}

ContentCards.displayName = 'ContentCards'

export default ContentCards
