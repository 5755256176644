'use client'

import classnames from 'classnames'
import styles from './TextAndImage.module.scss'
import SanityImage from '@/components/SanityImage/SanityImage'
import VideoLoop from '@/components/VideoLoop/VideoLoop'
import Button from '@/components/Button/Button'
import TextAndImageRichText from './TextAndImageRichText'
import { CSSProperties } from 'react'
import Widont from '@/components/Widont/Widont'
import AnimateContainer from '@/components/AnimateContainer/AnimateContainer'
import useI18n from '@/hooks/use-i18n'

const TextAndImage = ({ className, title, eyebrow, media, textPosition, description, cta }: SanityTextAndImage) => {
  const { mediaType, videoLoopDesktop, videoLoopMobile, image, mediaStyle } = media || {}
  const { i18n } = useI18n()

  if (!title || !media.mediaStyle || !media.mediaType) return null

  return (
    <div
      className={classnames(styles.TextAndImage, className)}
      data-text-position={textPosition}
    >
      <div className={styles.inner}>
        <AnimateContainer
          className={styles.textContainer}
          animationType="fadeIn"
        >
          <div className={styles.titleContainer}>
            {eyebrow && <p className={styles.eyebrow}>{eyebrow}</p>}
            <h1 className={styles.textAndImageTitle}>
              <Widont text={title} />
            </h1>
          </div>
          <div>
            {typeof description === 'string' ? (
              <p className={styles.descriptionText}>{description}</p>
            ) : (
              <TextAndImageRichText content={description} />
            )}
          </div>
          {cta && cta.linkType !== 'disabled' && (
            <div>
              <Button
                className={styles.cta}
                link={cta}
                icon="caretRight"
                ariaLabel={i18n('ariaVisitLink')}
              />
            </div>
          )}
        </AnimateContainer>
        <AnimateContainer
          animationType="fadeInUp"
          className={classnames(styles.mediaContainer, styles[mediaStyle])}
          style={
            {
              '--aspect-ratio': `${image?.asset?.metadata?.dimensions?.width}/${image?.asset?.metadata?.dimensions?.height}`,
            } as CSSProperties
          }
        >
          <div className={styles.mediaContainerInner}>
            {mediaType === 'image' && image && (
              <SanityImage
                image={image}
                className={styles.image}
                breakpoints={{
                  xs: {
                    width: 800,
                    image,
                  },
                  tablet: {
                    width: 1200,
                    image,
                  },
                }}
                customization={{
                  width: 2500,
                  height: 2500,
                }}
              />
            )}
            {mediaType === 'video' && videoLoopMobile && videoLoopDesktop && (
              <VideoLoop
                desktopLoop={videoLoopDesktop}
                mobileLoop={videoLoopMobile}
                desktopSizeMb={3}
                mobileSizeMb={0.75}
                className={styles.video}
              />
            )}
          </div>
        </AnimateContainer>
      </div>
    </div>
  )
}

TextAndImage.displayName = 'TextAndImage'

export default TextAndImage
