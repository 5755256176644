'use client'

import classnames from 'classnames'
import styles from './ThreeUp.module.scss'
import Button from '@/components/Button/Button'

import ThreeUpMenuItemCard from '@/sections/ThreeUp/ThreeUpMenuItemCard/ThreeUpMenuItemCard'
import ThreeUpBlogPostCard from '@/sections/ThreeUp/ThreeUpBlogPostCard/ThreeUpBlogPostCard'
import ThreeUpLocationCard from '@/sections/ThreeUp/ThreeUpLocationCard/ThreeUpLocationCard'
import ThreeUpPressHighlightCard from '@/sections/ThreeUp/ThreeUpPressHighlightCard/ThreeUpPressHighlightCard'
import { CSSProperties } from 'react'
import useBreakpoint from '@/hooks/use-breakpoint'

const ThreeUp = ({ className, title, cta, items, mobileStyle }: SanityThreeUp) => {
  const listStyle = mobileStyle || 'listed'
  const { isMobile } = useBreakpoint()

  if (!title || items?.length !== 3) return null

  return (
    <div
      className={classnames(styles.ThreeUp, className)}
      data-list-style={listStyle}
      style={
        {
          '--items-count': items.length,
        } as CSSProperties
      }
    >
      <div className={styles.inner}>
        <div className={styles.titleCtaContainer}>
          <h1 className={styles.title}>{title}</h1>
          {cta && (
            <div>
              <Button
                className={styles.cta}
                link={cta}
                icon="caretRight"
              />
            </div>
          )}
        </div>
        <div
          className={styles.items}
          {...(isMobile && listStyle === 'horizontalScroll' ? { 'data-lenis-prevent': '' } : {})}
        >
          <div className={styles.itemsInner}>
            {items.map((item, i) => {
              let Component = null

              switch (item._type) {
                case 'location':
                  Component = ThreeUpLocationCard
                  break
                case 'menuItem':
                  Component = ThreeUpMenuItemCard
                  break
                case 'blogPost':
                  Component = ThreeUpBlogPostCard
                  break
                case 'pressHighlight':
                  Component = ThreeUpPressHighlightCard
                  break
                default:
                  break
              }

              if (!Component) return null

              return (
                <Component
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  {...(item as any)}
                  key={i}
                  menuItemsStaggeredStyle={listStyle === 'staggered'}
                  className={styles.item}
                />
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

ThreeUp.displayName = 'ThreeUp'

export default ThreeUp
