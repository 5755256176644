import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import styles from './TextAndImageHeroWithBreadcrumbs.module.scss'
import Button from '@/components/Button/Button'
import SanityImage from '@/components/SanityImage/SanityImage'
import { useEffect, useState } from 'react'
import StaggeredImagesComponent from '@/components/StaggeredImages/StaggeredImages'
import Widont from '@/components/Widont/Widont'

const TextAndImageHeroWithBreadcrumbs = ({
  title,
  subtitle,
  description,
  images,
  breadcrumbLink1,
  breadcrumbLink2,
  staggeredImages,
  cta,
  mediaStyle,
}: TextAndImageHeroWithBreadcrumbsProps) => {
  const [mainImage, setMainImage] = useState<SanityImage | null>(null)

  useEffect(() => {
    if (!images?.length) return
    let selectedImage: SanityImage | null = null

    if (images?.length) {
      selectedImage = images[0]
    }

    setMainImage(selectedImage)
  }, [images])

  if (!title || !breadcrumbLink1 || !breadcrumbLink2 || !images?.length || !mediaStyle) return null

  return (
    <div
      className={styles.TextAndImageHeroWithBreadcrumbs}
      data-media-style={mediaStyle}
    >
      <div className={styles.inner}>
        <div className={styles.grid}>
          <div className={styles.textSide}>
            <Breadcrumbs
              links={[breadcrumbLink1, breadcrumbLink2]}
              className={styles.breadcrumbs}
            />
            <h1 className={styles.title}>
              <Widont text={title} />
            </h1>
            {subtitle && (
              <p className={styles.subtitle}>
                <Widont text={subtitle} />
              </p>
            )}
            {description && (
              <p className={styles.description}>
                <Widont text={description} />
              </p>
            )}
            <Button
              className={styles.button}
              link={cta}
              primary
              icon="caretRight"
            />
          </div>
          <div className={styles.imageSide}>
            <div className={styles.imageContainer}>
              {mainImage && (
                <SanityImage
                  image={mainImage}
                  breakpoints={{
                    tablet: {
                      width: 1200,
                      image: mainImage,
                    },
                    xs: {
                      width: 800,
                      image: mainImage,
                    },
                  }}
                  className={styles.featuredImage}
                />
              )}
            </div>
          </div>
        </div>
        {(staggeredImages as SanityStaggeredItem[])?.length > 1 && (
          <StaggeredImagesComponent
            items={staggeredImages}
            className={styles.staggeredImages}
          />
        )}
      </div>
    </div>
  )
}

TextAndImageHeroWithBreadcrumbs.displayName = 'TextAndImageHeroWithBreadcrumbs'

export default TextAndImageHeroWithBreadcrumbs
