'use client'

import classnames from 'classnames'
import styles from './StaggeredImages.module.scss'
import { useEffect, useState } from 'react'
import StaggeredImagesItem from '@/components/StaggeredImages/StaggeredImagesItem/StaggeredImagesItem'

type StaggeredImagesProps = {
  className?: string
  items?: SanityStaggeredItem[]
}

const StaggeredImages = ({ className, items }: StaggeredImagesProps) => {
  const [currentItems, setCurrentItems] = useState<SanityStaggeredItem[]>([])

  useEffect(() => {
    if (!items?.length) return
    const first3 = items.slice(0, 3).filter((item: SanityStaggeredItem) => item)
    setCurrentItems(first3)
  }, [items])

  if (currentItems.length !== 3 && currentItems.length !== 2) return null

  return (
    <div
      className={classnames(styles.StaggeredImages, className)}
      data-image-count={currentItems.length}
    >
      {currentItems.map((item, i) => (
        <StaggeredImagesItem
          key={i}
          index={i}
          {...item}
        />
      ))}
    </div>
  )
}

StaggeredImages.displayName = 'StaggeredImages'

export default StaggeredImages
