'use client'

import classnames from 'classnames'
import styles from './StaggeredImagesItem.module.scss'
import { useEffect, useRef } from 'react'
import AnimateContainer from '@/components/AnimateContainer/AnimateContainer'
import VideoLoop from '@/components/VideoLoop/VideoLoop'
import SanityImage from '@/components/SanityImage/SanityImage'
import UseStaggeredImagesParallax from '@/components/StaggeredImages/UseStaggeredImagesParallax'

type StaggeredImagesItemProps = SanityStaggeredItem & {
  className?: string
  index: number
}

const StaggeredImagesItem = ({
  className,
  index,
  mediaType,
  image,
  videoLoopDesktop,
  videoLoopMobile,
}: StaggeredImagesItemProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const { destroyParallax, setParallax } = UseStaggeredImagesParallax()

  useEffect(() => {
    if (!containerRef?.current) return
    setParallax({ element: containerRef?.current })
  }, [setParallax])

  useEffect(() => {
    const el = containerRef?.current
    return () => {
      if (el) {
        destroyParallax({ element: el })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      ref={containerRef}
      className={classnames(styles.StaggeredImagesItem, className)}
    >
      <AnimateContainer
        animationType="fadeIn"
        delay={index * 0.15}
        className={styles.imageContainer}
      >
        {mediaType === 'image' && image && (
          <SanityImage
            image={image}
            className={styles.image}
            breakpoints={{
              laptop: {
                width: 1000,
                image: image,
              },
              xs: {
                width: 800,
                image: image,
              },
            }}
          />
        )}
        {mediaType === 'video' && videoLoopDesktop && videoLoopMobile && (
          <VideoLoop
            desktopLoop={videoLoopDesktop}
            mobileLoop={videoLoopMobile}
            desktopSizeMb={3}
            mobileSizeMb={0.75}
            className={styles.video}
          />
        )}
      </AnimateContainer>
    </div>
  )
}

StaggeredImagesItem.displayName = 'StaggeredImagesItem'

export default StaggeredImagesItem
