import React, { useState, useEffect, useRef, useImperativeHandle } from 'react'
import classnames from 'classnames'
import styles from './Textarea.module.scss'

const Textarea = React.forwardRef<TextAreaRef, TextAreaProps>(
  ({ className, placeholder, value, onChange, onFocus, onBlur, ariaLabel }, ref) => {
    const [isFocused, setIsFocused] = useState(false)
    const textareaRef = useRef<HTMLTextAreaElement>(null)
    const [placeholderValue, setPlaceholder] = useState(placeholder)
    const [textareaClass, setTextareaClass] = useState(false)

    useImperativeHandle(ref, () => ({
      focus: () => {
        textareaRef.current?.focus()
      },
    }))

    const handleFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
      setIsFocused(true)
      setPlaceholder('')
      if (onFocus) onFocus(e)
    }

    const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
      setIsFocused(false)
      setPlaceholder(placeholder)
      if (onBlur) onBlur(e)
    }

    useEffect(() => {
      setTextareaClass(!!value)
    }, [value])

    return (
      <textarea
        ref={textareaRef}
        className={classnames(textareaClass && styles.textareaTextColor, styles.Textarea, className, {
          [styles.focused]: isFocused,
        })}
        placeholder={placeholderValue}
        value={value}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        aria-label={ariaLabel}
      />
    )
  },
)

Textarea.displayName = 'Textarea'

export default Textarea
