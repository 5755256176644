import BlogPostRichText from '@/sections/BlogPost/BlogPostRichText'

const BlogPost = ({ richTextContent, title }: BlogPost) => {
  return (
    <div>
      <h1>Blog post: {title}</h1>
      {richTextContent && <BlogPostRichText content={richTextContent} />}
    </div>
  )
}

BlogPost.displayName = 'BlogPost'

export default BlogPost
