'use client'

import classnames from 'classnames'
import styles from './MerchandiseListingItem.module.scss'
import SanityImage from '@/components/SanityImage/SanityImage'
import useStore from '@/store'
import useI18n from '@/hooks/use-i18n'
import { useRef } from 'react'
import Button from '@/components/Button/Button'

type MerchandiseShowcaseItemProps = SanityMerchandiseItem & {
  className?: string
}

const MerchandiseListingItem = (props: MerchandiseShowcaseItemProps) => {
  const { className, title, images, price, collectionName, status } = props
  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const setMerchSlideoutData = useStore(state => state.setMerchSlideoutData)
  const setLastElementInFocus = useStore(state => state.setLastElementInFocus)
  const mainImage = images[0]
  const { i18n } = useI18n()

  let priceContent = (
    <span>
      {collectionName && <span className={styles.collectionName}>{collectionName} - </span>} {price}
    </span>
  )
  if (status === 'soldOut') {
    priceContent = <span>{i18n('soldOut')}</span>
  } else if (status === 'soldOutComingSoon') {
    priceContent = <span>{i18n('soldOutComingBackSoon')}</span>
  } else if (status === 'comingSoon') {
    priceContent = <span>{i18n('merchComingSoon')}</span>
  }

  if (!mainImage) return null

  return (
    <button
      ref={buttonRef}
      className={classnames(styles.MerchandiseListingItem, className)}
      onClick={() => {
        setMerchSlideoutData(props)
        setLastElementInFocus(buttonRef.current)
      }}
    >
      <span className={styles.imageContainer}>
        <SanityImage
          image={mainImage}
          className={styles.image}
          breakpoints={{
            tablet: {
              width: 900,
              image: mainImage,
            },
            xs: {
              width: 750,
              image: mainImage,
            },
          }}
        />
      </span>
      <span className={styles.title}>{title}</span>
      <span className={styles.price}>{priceContent}</span>
      <Button
        className={styles.cta}
        type="submit"
        secondary
        icon="caretRight"
        label={i18n('viewDetails')}
      />
    </button>
  )
}

MerchandiseListingItem.displayName = 'MerchandiseListingItem'

export default MerchandiseListingItem
