'use client'

import classnames from 'classnames'
import styles from './LocationsList.module.scss'
import { useEffect, useState } from 'react'
import STATES from '@/data/states'
import LocationsListItem from '@/sections/LocationsList/LocationsListItem/LocationsListItem'
import HeaderWithCtas from '@/components/HeaderWithCtas/HeaderWithCtas'
import Image from 'next/image'
import ContentWithStickyNavigation, {
  ContentWithStickyNavigationLineItem,
} from '@/components/ContentWithStickyNavigation/ContentWithStickyNavigation'
import { getLocationListItemId, proxyAssetCdn } from '@/utils'
import useI18n from '@/hooks/use-i18n'

type LocationItemsType = { state: string; items: SanityPage[] }[]

// eslint-disable-next-line
const LocationsList = ({
  className,
  items,
  orderOfStates,
  headerContent,
  backgroundSvg,
  sectionBackground,
  hideHeaderContent,
  showPrivateDiningOnly,
}: SanityLocationsList) => {
  const [locationItems, setLocationItems] = useState<LocationItemsType>([])
  const [navigationItems, setNavigationItems] = useState<ContentWithStickyNavigationLineItem[]>([])
  const { i18n } = useI18n()

  useEffect(() => {
    if (!items?.length) return

    const _orderOfStates = orderOfStates || []
    const locationsOrder = [..._orderOfStates]
    let locationsByState: LocationItemsType = []

    Object.keys(STATES).forEach(state => {
      if (!locationsOrder.includes(state)) {
        locationsOrder.push(state)
      }
    })

    locationsOrder.forEach(state => {
      locationsByState.push({ state, items: [] })
    })

    locationsByState.forEach(item => {
      const state = item.state
      items.forEach(locationItem => {
        if (locationItem?.locationData?.state === state) {
          item.items.push(locationItem)
        }
      })
      item.items.sort((a, b) => a.title.localeCompare(b.title))
    })

    locationsByState = locationsByState.filter(item => item?.items?.length)

    setLocationItems(locationsByState)

    const locationsFormattedForNavigation: ContentWithStickyNavigationLineItem[] = []
    locationsByState.forEach(item => {
      locationsFormattedForNavigation.push({
        title: i18n(item.state) as string,
        count: item.items.length,
        id: getLocationListItemId(item.state),
      })
    })

    setNavigationItems(locationsFormattedForNavigation)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, orderOfStates])

  return (
    <div className={classnames(styles.LocationsList, className)}>
      <div className={styles.inner}>
        {!hideHeaderContent && (
          <div className={styles.header}>
            <HeaderWithCtas
              {...headerContent}
              className={styles.headerContent}
            />
            <div className={styles.imageContainer}>
              {backgroundSvg && (
                <Image
                  src={proxyAssetCdn(backgroundSvg?.asset?.url)}
                  alt=""
                  className={styles.image}
                  width={1160}
                  height={660}
                  loading="eager"
                />
              )}
            </div>
          </div>
        )}
        <ContentWithStickyNavigation
          sectionBackground={sectionBackground}
          lineItems={navigationItems}
          contentGroupQuerySelector="[data-location]"
          className={styles.contentWithStickyNav}
        >
          <>
            {locationItems.length && (
              <ul className={styles.list}>
                {locationItems.map(statesItem => (
                  <li
                    className={styles.listItem}
                    key={statesItem.state}
                    data-location={getLocationListItemId(statesItem.state)}
                    data-state-list={`${statesItem.state}`}
                    id={getLocationListItemId(statesItem.state)}
                  >
                    <h1 className={styles.listItem__title}>{i18n(statesItem.state)}</h1>
                    <ul className={styles.locationsList}>
                      {statesItem.items.map((locationItem, i) => (
                        <LocationsListItem
                          index={i}
                          totalItems={statesItem.items.length}
                          showPrivateDiningOnly={showPrivateDiningOnly}
                          className={styles.locationsList__item}
                          {...locationItem}
                          key={i}
                        />
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            )}
          </>
        </ContentWithStickyNavigation>
      </div>
    </div>
  )
}

LocationsList.displayName = 'LocationsList'

export default LocationsList
