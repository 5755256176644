'use client'

import classnames from 'classnames'
import styles from './MenuListingListItem.module.scss'
import SanityImage from '@/components/SanityImage/SanityImage'
import Button from '@/components/Button/Button'
import useI18n from '@/hooks/use-i18n'
import { DOC_TYPES } from '@/data'
import { useState } from 'react'
import Link from '@/components/Link/Link'
import Widont from '@/components/Widont/Widont'
import useBreakpoint from '@/hooks/use-breakpoint'

type MenuListingListItem = SanityPage & {
  className?: string
}

const MenuListingListItem = ({ className, menuItemData, title, slug }: MenuListingListItem) => {
  const [isHovering, setIsHovering] = useState(false)
  const { isMobile } = useBreakpoint()
  const { i18n } = useI18n()
  let imageToUse = null
  if (menuItemData?.images?.length) {
    imageToUse = menuItemData.images[0]
  } else if (menuItemData?.lifestyleImages?.length) {
    imageToUse = menuItemData?.lifestyleImages[0]
  }

  return (
    <li
      className={classnames(className, styles.MenuListingListItem, { [styles.isHovering]: isHovering && !isMobile })}
      onMouseEnter={() => {
        setIsHovering(true)
      }}
      onMouseLeave={() => {
        setIsHovering(false)
      }}
    >
      <Link
        link={{
          linkType: 'internal',
          link: {
            slug: slug.current,
            _type: DOC_TYPES.MENU_ITEM,
          },
        }}
        className={styles.linkContainer}
      >
        <div className={styles.imageContainer}>
          {imageToUse && (
            <SanityImage
              image={imageToUse}
              breakpoints={{
                tablet: {
                  width: 550,
                  image: imageToUse,
                },
                xs: {
                  width: 400,
                  image: imageToUse,
                },
              }}
              className={styles.image}
            />
          )}
        </div>
        <div className={styles.textContent}>
          <div className={styles.textContentTop}>
            <h1 className={styles.title}>
              <Widont text={title} />
            </h1>
            {menuItemData?.description && (
              <p className={styles.description}>
                <Widont text={menuItemData.description} />
              </p>
            )}
          </div>
          <div className={styles.buttonContainer}>
            <Button
              element="span"
              className={styles.button}
              plain
              icon="caretRight"
              label={i18n('learnMore')}
            />
          </div>
        </div>
      </Link>
    </li>
  )
}

MenuListingListItem.displayName = 'MenuListingListItem'

export default MenuListingListItem
