import styles from './ContactFormResults.module.scss'

const ContactFormResults = ({ status, successMessage, errorMessage }: ContactFormResultsProps) => {
  const isSuccess = status === 'success'
  const className = isSuccess ? styles.ContactFormResultsSuccess : styles.ContactFormResultsError

  return (
    <div className={styles.ContactFormResults}>
      <div className={className}>{isSuccess ? successMessage : errorMessage}</div>
    </div>
  )
}

export default ContactFormResults
