import { PortableText, PortableTextReactComponents } from '@portabletext/react'
import styles from './HeaderWithCtas.module.scss'

const portableTextSerializer: Partial<PortableTextReactComponents> = {
  types: {},
  block: {
    normal: ({ children }) => <p className={styles.rtaNormalText}>{children}</p>,
    h1: ({ children }) => <h1 className={styles.rtaH1Text}>{children}</h1>,
    h2: ({ children }) => <h1 className={styles.rtaH1Text}>{children}</h1>,
  },
  marks: {
    redText: ({ text }) => {
      return <span className={styles.rtaAsterisk}>{text}</span>
    },
  },
}

function HeaderWithCtasRichText({ content }: SanityContentBlockProps) {
  return (
    <PortableText
      value={content}
      components={portableTextSerializer}
      onMissingComponent={false}
    />
  )
}

export default HeaderWithCtasRichText
