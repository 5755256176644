import { PortableText, PortableTextReactComponents } from '@portabletext/react'
import Link from '@/components/Link/Link'
import styles from './RichTextContent.module.scss'
import Button from '@/components/Button/Button'

const portableTextSerializer: Partial<PortableTextReactComponents> = {
  marks: {
    link: ({ value, text }) => (
      <Link
        link={{
          ...value,
          label: text,
        }}
        className={styles.richTextLink}
      />
    ),
  },
  block: {
    normal: ({ children }) => <p className={styles.contentText}>{children}</p>,
    h1: ({ children }) => <h1 className={styles.contentH1}>{children}</h1>,
    h2: ({ children }) => <h2 className={styles.contentH2}>{children}</h2>,
    h3: ({ children }) => <h3 className={styles.contentH3}>{children}</h3>,
  },
  listItem: {
    unordered: ({ children }) => <li className={styles.contentListElement}>{children}</li>,
    ordered: ({ children }) => <li className={styles.contentListElement}>{children}</li>,
  },
  list: {
    unordered: ({ children }) => <ul className={styles.contentList}>{children}</ul>,
    ordered: ({ children }) => <ol className={styles.contentList}>{children}</ol>,
  },
  types: {
    link: ({ value }) => (
      <Button
        className={styles.cta}
        link={value}
        icon="caretRight"
        secondary
        disableHoverAnimation
      />
    ),
  },
}

function RichTextContentRichText({ content }: SanityContentBlockProps) {
  return (
    <PortableText
      value={content}
      onMissingComponent={false}
      components={portableTextSerializer}
    />
  )
}

export default RichTextContentRichText
