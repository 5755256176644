'use client'

import classnames from 'classnames'
import styles from './RichTextContent.module.scss'

import RichTextContentRichText from './RichTextContentRichText'

const RichTextContent = ({ className, content }: SanityRichTextContent) => {
  return (
    <div className={classnames(styles.RichTextContent, className)}>
      <div className={styles.inner}>
        <div className={styles.richTextContentContainer}>
          <RichTextContentRichText content={content} />
        </div>
      </div>
    </div>
  )
}

RichTextContent.displayName = 'RichTextContent'

export default RichTextContent
