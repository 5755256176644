'use client'

import classnames from 'classnames'
import styles from './EcommerceItemCard.module.scss'
import SanityImage from '@/components/SanityImage/SanityImage'
import Button from '@/components/Button/Button'
import { useRef } from 'react'
import useI18n from '@/hooks/use-i18n'
import { getDomainNameFromUrlString, getServiceNameKeyFromUrl } from '@/data/sanity/utils'
import useBreakpoint from '@/hooks/use-breakpoint'

type SanityEcommerceItemCard = SanityEcommerceItem & {
  className?: string
}

const EcommerceItemCard = ({ className, price, title, purchaseLink, image }: SanityEcommerceItemCard) => {
  const buttonImpHandleRef = useRef<ButtonImperativeHandle | null>(null)
  const { i18n } = useI18n()
  const { isMobile } = useBreakpoint()

  if (!price || !title || !purchaseLink || !image) {
    return null
  }

  return (
    <article className={classnames(styles.EcommerceItemCard, className)}>
      <a
        href={purchaseLink}
        target="_blank"
        className={styles.linkContainer}
        onMouseEnter={() => {
          if (isMobile) return
          buttonImpHandleRef.current?.setIsHover(true)
        }}
        onMouseLeave={() => {
          if (isMobile) return
          buttonImpHandleRef.current?.setIsHover(false)
        }}
      >
        <div className={styles.imageContainer}>
          <SanityImage
            image={image}
            className={styles.image}
            breakpoints={{
              tablet: {
                width: 800,
                image,
              },
              xs: {
                width: 700,
                image,
              },
            }}
          />
        </div>
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.price}>{price}</p>
        <div className={styles.buttonContainer}>
          <Button
            className={styles.button}
            secondary
            icon="caretRight"
            element="span"
            disableHoverAnimation
            ref={ref => {
              if (!ref) return
              buttonImpHandleRef.current = ref
            }}
            label={i18n('viewOnWebsite', {
              website: i18n(getServiceNameKeyFromUrl(purchaseLink)) || getDomainNameFromUrlString(purchaseLink),
            })}
          />
        </div>
      </a>
    </article>
  )
}

EcommerceItemCard.displayName = 'EcommerceItemCard'

export default EcommerceItemCard
