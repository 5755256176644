'use client'

import classnames from 'classnames'
import styles from './TextAndAccordion.module.scss'
import Accordion from '@/components/Accordion/Accordion'
import { getFormattedDateString } from '@/utils'
import Button from '@/components/Button/Button'
import useI18n from '@/hooks/use-i18n'
import SanityImage from '@/components/SanityImage/SanityImage'
import Widont from '@/components/Widont/Widont'
import useBreakpoint from '@/hooks/use-breakpoint'
import AnimateContainer from '@/components/AnimateContainer/AnimateContainer'
import VideoLoop from '@/components/VideoLoop/VideoLoop'
import TextAndAccordionRichText from './TextAndAccordionRichText'

const TextAndAccordion = ({
  className,
  title,
  description,
  items,
  cta,
  image,
  mainContent,
  videoLoopDesktop,
  videoLoopMobile,
}: SanityTextAndAccordion) => {
  const accordionItems: AccordionItem[] = []
  const { i18n } = useI18n()
  const { isMobile } = useBreakpoint()

  items.forEach(item => {
    if (item.contentType === 'plainText' && item.title && item.description) {
      accordionItems.push({
        title: item.title,
        description: item.description,
      })
    } else if (item.contentType === 'pressRelease' && item.pressReleases?.length) {
      const content = (
        <div className={styles.pressReleaseContainer}>
          {item.pressReleases.map((pressRelease, i) => {
            let url = ''
            if (pressRelease?.linkType === 'file' && pressRelease.file) {
              url = pressRelease.file?.asset?.url
            } else if (pressRelease?.linkType === 'link' && pressRelease.link) {
              url = pressRelease.link
            }

            return (
              <div
                className={styles.pressReleaseItem}
                key={i}
              >
                <p className={styles.pressReleaseItem__date}>{getFormattedDateString(pressRelease.publishedDate)}</p>
                <p className={styles.pressReleaseItem__title}>{pressRelease.title}</p>
                <Button
                  className={styles.pressReleaseItem__button}
                  plain
                  link={{
                    label: i18n('readMore'),
                    linkType: 'external',
                    link: url,
                    icon: 'caretRight',
                  }}
                  ariaLabel={i18n('ariaGoToPressRelease', { year: getFormattedDateString(pressRelease.publishedDate) })}
                />
              </div>
            )
          })}
        </div>
      )

      const firstPressRelease = item.pressReleases[0]
      const firstYear = firstPressRelease.publishedDate.split('-')[0]

      accordionItems.push({
        title: firstYear,
        description: content,
      })
    }
  })

  let buttonContent = null
  if (cta) {
    buttonContent = (
      <Button
        className={styles.button}
        link={cta}
        secondary
        icon="caretRight"
      />
    )
  }

  if (!items?.length) return null

  return (
    <div
      className={classnames(styles.TextAndAccordion, className)}
      data-content-type={mainContent}
    >
      <div className={styles.inner}>
        <AnimateContainer
          animationType="fadeIn"
          className={styles.textSide}
        >
          {mainContent === 'image' && image?.asset?.url && (
            <div className={styles.mediaContainer}>
              <SanityImage
                className={styles.image}
                image={image}
                breakpoints={{
                  tablet: {
                    width: 1200,
                    image,
                  },
                  xs: {
                    width: 800,
                    image,
                  },
                }}
              />
            </div>
          )}
          {mainContent === 'video' && videoLoopDesktop && videoLoopMobile && (
            <div className={styles.mediaContainer}>
              <VideoLoop
                desktopLoop={videoLoopDesktop}
                mobileLoop={videoLoopMobile}
                desktopSizeMb={3}
                mobileSizeMb={0.75}
                className={styles.video}
              />
            </div>
          )}
          {mainContent === 'text' && title && description && (
            <>
              <h1 className={styles.title}>
                <Widont text={title} />
              </h1>
              {typeof description === 'string' ? (
                <p className={styles.description}>
                  <Widont text={description} />
                </p>
              ) : (
                <div className={styles.description}>
                  <TextAndAccordionRichText content={description} />
                </div>
              )}
              <div className={styles.desktopButton}>{buttonContent}</div>
            </>
          )}
        </AnimateContainer>
        <AnimateContainer
          animationType="fadeIn"
          className={styles.accordionSide}
          delay={0.15}
        >
          <Accordion
            items={accordionItems}
            className={styles.accordion}
            initialOpenIndex={0}
          />
          <div className={styles.imageSideMobileButton}>{(mainContent === 'image' || isMobile) && buttonContent}</div>
        </AnimateContainer>
      </div>
    </div>
  )
}

TextAndAccordion.displayName = 'TextAndAccordion'

export default TextAndAccordion
