'use client'

import classnames from 'classnames'
import styles from './ValuesList.module.scss'
import SanityImage from '@/components/SanityImage/SanityImage'
import Widont from '@/components/Widont/Widont'

const ValuesList = ({ className, items }: SanityValuesList) => {
  return (
    <div className={classnames(styles.ValuesList, className)}>
      <div className={styles.inner}>
        <div className={styles.list}>
          {items.map((item, index) => (
            <div
              key={index}
              className={styles.itemContainer}
            >
              <div className={classnames(styles.mediaContainer, styles[item.imageStyle])}>
                <div className={styles.mediaContainerInner}>
                  {item.image && (
                    <SanityImage
                      image={item.image}
                      className={styles.image}
                      breakpoints={{
                        xs: {
                          width: 800,
                          image: item.image,
                        },
                        tablet: {
                          width: 1200,
                          image: item.image,
                        },
                      }}
                    />
                  )}
                </div>
              </div>
              <div className={styles.titleContainer}>
                <h2 className={styles.title}>
                  <Widont text={item.title} />
                </h2>
              </div>
              <div>
                <p className={styles.descriptionText}>
                  <Widont text={item.description} />
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

ValuesList.displayName = 'ValuesList'

export default ValuesList
