'use client'

import classnames from 'classnames'
import styles from './TimelineItem.module.scss'
import Widont from '@/components/Widont/Widont'
import TimelineItemRichText from '@/sections/Timeline/TimelineItem/TimelineItemRichText'
import SanityImage from '@/components/SanityImage/SanityImage'
import useI18n from '@/hooks/use-i18n'

type SanityTimelineItemProps = SanityTimelineItem & {
  className?: string
  setActiveIndex?: (index: number) => void
  index: number
}

const TimelineItem = ({
  className,
  year,
  title,
  eyebrow,
  description,
  image,
  imageStyle,
  index,
  setActiveIndex,
}: SanityTimelineItemProps) => {
  const { i18n } = useI18n()
  const hasImage = image?.asset?.url

  if (!year || !title) {
    return null
  }

  return (
    <button
      className={classnames(styles.TimelineItem, className, { [styles.hasImage]: hasImage })}
      data-image-style={imageStyle}
      onFocus={() => {
        if (setActiveIndex) {
          setActiveIndex(index)
        }
      }}
      aria-label={i18n('timelineYearAndTitleAria', { year, title })}
    >
      <span className={styles.inner}>
        <span className={styles.imageSide}>
          {hasImage && (
            <span className={styles.imageContainer}>
              <SanityImage
                className={styles.image}
                image={image}
                breakpoints={{
                  tablet: {
                    width: 800,
                    image,
                  },
                  xs: {
                    width: 700,
                    image,
                  },
                }}
              />
            </span>
          )}
        </span>
        <span className={styles.textSide}>
          <span className={styles.titleContent}>
            {eyebrow && (
              <span className={styles.eyebrow}>
                <span className={styles.asterisk}>*</span>
                {eyebrow}
              </span>
            )}
            <span className={styles.title}>
              <Widont text={title} />
            </span>
          </span>
          {(description as SanityBlockContent[])?.length > 0 && (
            <span className={styles.rta}>
              <TimelineItemRichText content={description || []} />
            </span>
          )}
        </span>
      </span>
    </button>
  )
}

TimelineItem.displayName = 'TimelineItem'

export default TimelineItem
