'use client'

import classnames from 'classnames'
import styles from './RichTextHero.module.scss'
import HeaderWithCtas from '@/components/HeaderWithCtas/HeaderWithCtas'

const RichTextHero = ({ className, headerContent }: SanityRichTextHero) => {
  return (
    <div className={classnames(styles.RichTextHero, className)}>
      <div className={styles.inner}>
        <div className={styles.grid}>
          <div className={styles.gridContent}>
            <HeaderWithCtas {...headerContent} />
          </div>
        </div>
      </div>
    </div>
  )
}

RichTextHero.displayName = 'RichTextHero'

export default RichTextHero
