'use client'

import classnames from 'classnames'
import styles from './MenuListing.module.scss'
import ContentWithStickyNavigation from '@/components/ContentWithStickyNavigation/ContentWithStickyNavigation'
import { getMenuItemListingGroupId } from '@/utils'
import MenuListingListItem from '@/sections/MenuListing/MenuListingListItem/MenuListingListItem'
import Button from '@/components/Button/Button'
import useI18n from '@/hooks/use-i18n'
import { useContext } from 'react'
import { GlobalSettingsContext } from '@/context/GlobalSettings'
import Widont from '@/components/Widont/Widont'
import AnimateContainer from '@/components/AnimateContainer/AnimateContainer'

const MenuListing = ({ className, itemsByCategory, sectionBackground }: SanityMenuListing) => {
  const { i18n } = useI18n()
  const { footerData } = useContext(GlobalSettingsContext)

  return (
    <div className={classnames(styles.MenuListing, className)}>
      <div className={styles.inner}>
        <ContentWithStickyNavigation
          sectionBackground={sectionBackground}
          lineItems={itemsByCategory.map(itemsByCategory => ({
            title: itemsByCategory.categoryReference.title,
            id: getMenuItemListingGroupId(itemsByCategory.categoryReference.slug.current),
          }))}
          className={styles.contentWithStickyNavigation}
          contentGroupQuerySelector="[data-menu-category]"
          widerNav
          bottomNavContent={
            <div className={styles.bottomNavContent}>
              <p className={styles.bottomNavContent__allergenText}>
                {<Widont text={i18n('menuAllergenText') as string} />}
              </p>
              {footerData?.allergenGuidePdf?.asset.url && (
                <Button
                  className={styles.bottomNavContent__button}
                  link={{
                    label: i18n('viewAllergenGuide'),
                    linkType: 'external',
                    link: footerData?.allergenGuidePdf?.asset.url,
                  }}
                  icon="caretRight"
                />
              )}
            </div>
          }
        >
          <ul className={styles.allCategories}>
            {itemsByCategory.map((category, i) => {
              return (
                <li
                  key={i}
                  className={styles.categoryContainer}
                  data-menu-category={category.categoryReference.slug.current}
                  id={getMenuItemListingGroupId(category.categoryReference.slug.current)}
                >
                  <AnimateContainer
                    element="h1"
                    animationType="fadeIn"
                    className={styles.categoryTitle}
                  >
                    {category.categoryReference.title}
                  </AnimateContainer>
                  <AnimateContainer
                    element="ul"
                    animationType="fadeIn"
                    className={styles.itemsList}
                  >
                    {category.items.map((item, _i) => {
                      return (
                        <MenuListingListItem
                          key={_i}
                          {...item}
                        />
                      )
                    })}
                  </AnimateContainer>
                </li>
              )
            })}
          </ul>
        </ContentWithStickyNavigation>
      </div>
    </div>
  )
}

MenuListing.displayName = 'MenuListing'

export default MenuListing
