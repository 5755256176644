'use client'

import classnames from 'classnames'
import styles from './ThreeUpPressHighlightCard.module.scss'
import { getFormattedDateString, proxyAssetCdn } from '@/utils'
import Image from 'next/image'
import Link from '@/components/Link/Link'
import Widont from '@/components/Widont/Widont'
import AnimateContainer from '@/components/AnimateContainer/AnimateContainer'
import useI18n from '@/hooks/use-i18n'
import Button from '@/components/Button/Button'
import SanityImage from '@/components/SanityImage/SanityImage'

type ThreeUpPressHighlightCardProps = SanityPressHighlight & {
  className?: string
  element?: 'article' | 'li'
}

const ThreeUpPressHighlightCard = ({
  className,
  title,
  link,
  logo,
  publishedDate,
  source,
  element,
}: ThreeUpPressHighlightCardProps) => {
  const { i18n } = useI18n()

  if (!title || !link || !logo || !publishedDate) return null

  return (
    <AnimateContainer
      element={element || 'article'}
      animationType="fadeIn"
      className={classnames(
        styles.ThreeUpPressHighlightCard,
        className,

        {
          [styles.tall]: logo.asset.metadata.dimensions.aspectRatio < 0.82,
        },
        {
          [styles.orientationSquare]:
            logo.asset.metadata.dimensions.aspectRatio <= 1.2 && logo.asset.metadata.dimensions.aspectRatio >= 0.82,
        },
        {
          [styles.shortRect]:
            logo.asset.metadata.dimensions.aspectRatio < 2 && logo.asset.metadata.dimensions.aspectRatio > 1.2,
        },
        {
          [styles.orientationLong]: logo.asset.metadata.dimensions.aspectRatio > 2,
        },
        {
          [styles.orientationXLong]: logo.asset.metadata.dimensions.aspectRatio > 4,
        },
      )}
    >
      <Link
        link={{
          label: '',
          linkType: 'external',
          link,
        }}
      >
        <div className={styles.imageContainer}>
          <div className={styles.imageBox}>
            {logo?.asset?.url.includes('svg') ? (
              <>
                <Image
                  src={proxyAssetCdn(logo?.asset?.url)}
                  alt={title}
                  className={styles.image}
                  loading="lazy"
                  width={200}
                  height={200}
                />
              </>
            ) : (
              <SanityImage
                image={logo}
                className={styles.image}
                breakpoints={{
                  tablet: {
                    width: 400,
                    image: logo,
                  },
                  xs: {
                    width: 200,
                    image: logo,
                  },
                }}
              />
            )}
          </div>
        </div>
        <p className={styles.date}>
          {getFormattedDateString(publishedDate)}
          {source && <span className={styles.source}>&nbsp;-&nbsp;{source}</span>}
        </p>
        <p className={styles.title}>
          <Widont text={title} />
        </p>
        <Button
          label={i18n('readMore')}
          plain
          icon="caretRight"
          className={styles.mobileButton}
        />
      </Link>
    </AnimateContainer>
  )
}

ThreeUpPressHighlightCard.displayName = 'ThreeUpPressHighlightCard'

export default ThreeUpPressHighlightCard
