'use client'

import classnames from 'classnames'
import styles from './AccordionItemsWithSideNavigation.module.scss'
import ContentWithStickyNavigation from '@/components/ContentWithStickyNavigation/ContentWithStickyNavigation'
import { buildIdFromText } from '@/utils'
import Accordion from '@/components/Accordion/Accordion'
import AccordionItemsWithSideNavigationRichText from '@/sections/AccordionItemsWithSideNavigation/AccordionItemsWithSideNavigationRichText'

const AccordionItemsWithSideNavigation = ({
  className,
  items,
  sectionBackground,
}: SanityAccordionItemsWithSideNavigation) => {
  if (!items?.length) return null

  return (
    <div className={classnames(styles.AccordionItemsWithSideNavigation, className)}>
      <div className={styles.inner}>
        <ContentWithStickyNavigation
          sectionBackground={sectionBackground}
          lineItems={items.map((group, _) => ({
            title: group.groupTitle,
            id: `id_group_${buildIdFromText(group.groupTitle)}`,
          }))}
          contentGroupQuerySelector="[data-accordion-group]"
          className={styles.contentWithStickyNav}
        >
          <>
            {items.length > 0 && (
              <ul className={styles.list}>
                {items.map((accordionGroup, i) => (
                  <li
                    className={styles.listItem}
                    key={i}
                    data-accordion-group={buildIdFromText(accordionGroup.groupTitle)}
                    id={`id_group_${buildIdFromText(accordionGroup.groupTitle)}`}
                  >
                    <h1 className={styles.listItem__title}>{accordionGroup.groupTitle}</h1>
                    <Accordion
                      className={styles.accordion}
                      items={accordionGroup.accordionItems.map((item, _) => {
                        return {
                          title: item.title,
                          description: (
                            <div className={styles.rta}>
                              <AccordionItemsWithSideNavigationRichText content={item.description} />
                            </div>
                          ),
                        }
                      })}
                    />
                  </li>
                ))}
              </ul>
            )}
          </>
        </ContentWithStickyNavigation>
      </div>
    </div>
  )
}

AccordionItemsWithSideNavigation.displayName = 'AccordionItemsWithSideNavigation'

export default AccordionItemsWithSideNavigation
