'use client'

import classnames from 'classnames'
import styles from './FourOhFour.module.scss'
import Button from '@/components/Button/Button'
import { DOC_TYPES } from '@/data'
import useI18n from '@/hooks/use-i18n'
import VideoLoop from '@/components/VideoLoop/VideoLoop'
import AnimateContainer from '@/components/AnimateContainer/AnimateContainer'
import SanityImage from '@/components/SanityImage/SanityImage'

const FourOhFour = ({ className, image, videoLoopMobile, videoLoopDesktop, mediaType }: SanityFourOhFour) => {
  const { i18n } = useI18n()

  return (
    <div className={classnames(styles.FourOhFour, className)}>
      <AnimateContainer
        element="div"
        animationType="fadeInUp"
        className={styles.item}
      >
        <div className={styles.inner}>
          <div className={styles.textContent}>
            <h2 className={styles.title}>{i18n('pageNotFound')}</h2>
            <p className={styles.description}>{i18n('sorryWeCantFindThePage')}</p>
            <Button
              primary
              link={{
                label: i18n('returnHome'),
                linkType: 'internal',
                link: {
                  _type: DOC_TYPES.PAGE,
                  slug: '/',
                },
              }}
            />
          </div>
          <div className={styles.mediaContent}>
            <div className={styles.imageContainer}>
              {mediaType === 'image' && image && (
                <SanityImage
                  image={image}
                  className={styles.image}
                  breakpoints={{
                    tablet: {
                      width: 1100,
                      image: image,
                    },
                    xs: {
                      width: 800,
                      image: image,
                    },
                  }}
                />
              )}
              {mediaType === 'video' && videoLoopMobile && videoLoopDesktop && (
                <VideoLoop
                  desktopLoop={videoLoopDesktop}
                  mobileLoop={videoLoopMobile}
                  desktopSizeMb={3}
                  mobileSizeMb={0.75}
                  className={styles.video}
                />
              )}
            </div>
          </div>
        </div>
      </AnimateContainer>
    </div>
  )
}

FourOhFour.displayName = 'FourOhFour'

export default FourOhFour
