import React, { useEffect } from 'react'

const ReplaceLastSpace = ({ text, index, arrayLength }: { text: string; index: number; arrayLength: number }) => {
  if (text.split(' ').length === 1) {
    return text
  }

  const lastIndex = text.lastIndexOf(' ')
  let part1 = text.slice(0, lastIndex)
  let part2 = text.slice(lastIndex + 1)

  // Handle registered trademark symbol
  const trademarkRegex = /®/g
  part1 = part1.replace(trademarkRegex, '<sup>®</sup>')
  part2 = part2.replace(trademarkRegex, '<sup>®</sup>')

  const needsSecondLine = index + 1 < arrayLength
  if (needsSecondLine) {
    part2 = `${part2}\n\n`
  }

  if (text.split(' ').length === 2) {
    return <span dangerouslySetInnerHTML={{ __html: text.replace(trademarkRegex, '<sup>®</sup>') }} />
  }

  return (
    <>
      <span dangerouslySetInnerHTML={{ __html: part1 }} />&nbsp;
      <span dangerouslySetInnerHTML={{ __html: part2 }} />
    </>
  )
}

export default function Widont({ text, log = false }: { text: string; log?: boolean }) {
  const _text = text.trim()

  let textAsArray = [_text]

  if (_text.includes('\n\n')) {
    textAsArray = _text.split('\n\n')
  }

  useEffect(() => {
    if (!log) return

    // eslint-disable-next-line no-console
    console.log({ text })
  }, [log, text])

  if (!text) return null

  return (
    <>
      {textAsArray.map((str, i) => (
        <React.Fragment key={i}>
          <ReplaceLastSpace
            text={str}
            index={i}
            arrayLength={textAsArray.length}
          />
        </React.Fragment>
      ))}
    </>
  )
}
