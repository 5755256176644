'use client'

import classnames from 'classnames'
import styles from './ThreeUpMenuItemCard.module.scss'
import Link from '@/components/Link/Link'
import SanityImage from '@/components/SanityImage/SanityImage'
import { useEffect, useRef, useState } from 'react'
import Widont from '@/components/Widont/Widont'
import AnimateContainer from '@/components/AnimateContainer/AnimateContainer'
import UseStaggeredImagesParallax from '@/components/StaggeredImages/UseStaggeredImagesParallax'

type ThreeUpMenuItemCardProps = SanityPage & {
  className?: string
  menuItemsStaggeredStyle: boolean
}

const ThreeUpMenuItemCard = ({
  className,
  title,
  slug,
  menuItemData,
  menuItemsStaggeredStyle,
}: ThreeUpMenuItemCardProps) => {
  const [image, setImage] = useState<SanityImage | null>(null)
  const containerRef = useRef<HTMLElement | null>(null)
  const { destroyParallax, setParallax } = UseStaggeredImagesParallax()

  useEffect(() => {
    if (!menuItemsStaggeredStyle) return
    setTimeout(() => {
      if (!containerRef.current) return
      setParallax({ element: containerRef.current })
    }, 150)
  }, [setParallax, menuItemsStaggeredStyle])

  useEffect(() => {
    if (!menuItemsStaggeredStyle) return
    const el = containerRef?.current
    return () => {
      if (el) {
        destroyParallax({ element: el })
      }
    }
  }, [destroyParallax, menuItemsStaggeredStyle])

  useEffect(() => {
    if (!menuItemData) return
    let selectedImage: SanityImage | null = null

    if (menuItemData?.images?.length) {
      selectedImage = menuItemData?.images[0]
    }

    if (menuItemData?.lifestyleImages?.length) {
      selectedImage = menuItemData?.lifestyleImages[0]
    }

    setImage(selectedImage)
  }, [menuItemData])

  if (!title || !slug?.current) return null

  return (
    <article
      ref={containerRef}
      className={classnames(styles.ThreeUpMenuItemCard, className, {
        [styles.isStaggeredStyle]: menuItemsStaggeredStyle,
      })}
    >
      <AnimateContainer animationType="fadeIn">
        <Link
          link={{
            label: '',
            linkType: 'internal',
            link: {
              _type: 'menuItem',
              slug: slug.current,
            },
          }}
          className={styles.linkContainer}
        >
          <div className={styles.imageContainer}>
            {image && (
              <SanityImage
                className={styles.image}
                image={image}
                breakpoints={{
                  tablet: {
                    image: image,
                    width: 900,
                    options: {
                      height: 900,
                      fit: 'crop',
                    },
                  },
                  xs: {
                    image: image,
                    width: 800,
                    options: {
                      height: 800,
                      fit: 'crop',
                    },
                  },
                }}
              />
            )}
          </div>
          <p className={styles.title}>
            <Widont text={title} />
          </p>
        </Link>
      </AnimateContainer>
    </article>
  )
}

ThreeUpMenuItemCard.displayName = 'ThreeUpMenuItemCard'

export default ThreeUpMenuItemCard
