'use client'

import classnames from 'classnames'
import styles from './LocationHeroHours.module.scss'
import { formatMilitaryTime, getMergedHoursWithHolidayHours } from '@/utils'
import { useEffect, useState } from 'react'
import useI18n from '@/hooks/use-i18n'
import RestaurantOpenClosedBlurb from '@/components/RestaurantOpenClosedBlurb/RestaurantOpenClosedBlurb'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { DAY_ORDER } from '@/data'
import Icon from '@/components/Icon/Icon'

ScrollTrigger.config({
  ignoreMobileResize: true,
})

gsap.registerPlugin(ScrollTrigger)

type LocationHeroHoursProps = Pick<SanityLocationData, 'timeZone' | 'hours' | 'holidayHours'> & {
  className?: string
}

type HoursConsolidatedType = { title: string; hours: string }[]

const LocationHeroHours = ({ className, timeZone, hours, holidayHours }: LocationHeroHoursProps) => {
  const hoursFormatted = typeof hours === 'string' ? JSON.parse(hours) : hours
  const [hoursByDayConsolidated, setHoursByDayConsolidated] = useState<HoursConsolidatedType>([])
  const [holidayHoursFiltered, setHolidayHoursFiltered] = useState<SanityLocationHolidayHours[]>([])
  const { i18n } = useI18n()
  const [holidayHoursOpen, setHolidayHoursOpen] = useState(false)

  useEffect(() => {
    const msSecond = 1000
    const msInMonth = msSecond * 60 * 60 * 24 * 30
    const todayTime = new Date().getTime()
    const _holidayHoursFiltered = holidayHours.filter(item => {
      const holidayDate = new Date(item.date)
      const holidayDateTime = holidayDate.getTime()
      const isPastCurrentDate = todayTime < holidayDateTime
      const isWithin30Days = holidayDateTime - todayTime > 0 && holidayDateTime - todayTime < msInMonth
      return isPastCurrentDate && isWithin30Days
    })
    setHolidayHoursFiltered(_holidayHoursFiltered)
  }, [holidayHours])

  useEffect(() => {
    const formattedArray: HoursConsolidatedType = []
    const hoursWithHolidayHours = getMergedHoursWithHolidayHours(hoursFormatted, holidayHours)

    DAY_ORDER.forEach(day => {
      const dayObject = hoursWithHolidayHours[day]
      if (dayObject) {
        const latestEntry = formattedArray[formattedArray.length - 1]
        const dayToLowercase = day.toLowerCase()
        let dayHoursFormatted = ''
        if (
          !dayObject.open ||
          !dayObject.close ||
          (dayObject.open === '00:00' && dayObject.close === '00:00') ||
          (dayObject.open === '0:00' && dayObject.close === '0:00') ||
          (dayObject.open === '0:0' && dayObject.close === '0:0')
        ) {
          dayHoursFormatted = i18n('locationClosed') || ''
        } else {
          dayHoursFormatted = `${formatMilitaryTime(dayObject.open)} - ${formatMilitaryTime(dayObject.close)}`
        }
        const dayTitleText = i18n(`${dayToLowercase}Short`)
        const dayDataToAdd = {
          title: dayTitleText || '',
          hours: dayHoursFormatted,
        }
        if (!latestEntry) {
          formattedArray[0] = dayDataToAdd
        } else {
          if (latestEntry.hours === dayHoursFormatted) {
            const titleSplit = latestEntry.title.split(' - ')
            latestEntry.title = `${titleSplit[0]} - ${dayTitleText}`
          } else {
            formattedArray.push(dayDataToAdd)
          }
        }
      }
    })

    setHoursByDayConsolidated(formattedArray)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoursFormatted])

  if (!hours || !timeZone) return null

  return (
    <div className={classnames(styles.LocationHeroHours, className)}>
      <RestaurantOpenClosedBlurb
        className={styles.openClosedBlurb}
        hours={hours}
        holidayHours={holidayHours}
        timeZone={timeZone}
      />
      <ul className={styles.dayHoursList}>
        {hoursByDayConsolidated.map((item, i) => {
          return (
            <li
              key={i}
              className={styles.dayHoursList__item}
            >
              <p className={styles.dayHoursList__item__day}>{item.title}</p>
              <p className={styles.dayHoursList__item__hours}>{item.hours}</p>
            </li>
          )
        })}
      </ul>
      {holidayHoursFiltered?.length > 0 && (
        <div
          className={styles.holidayHoursContainer}
          data-holidays-opened={holidayHoursOpen}
        >
          <button
            className={styles.titleButton}
            onClick={() => {
              setHolidayHoursOpen(prev => !prev)
            }}
          >
            <span className={styles.titleButton__text}>{i18n('holidayOpeningHours')}</span>
            <span className={styles.titleButton__iconContainer}>
              <Icon
                className={styles.titleButton__icon}
                name="caretDown"
              />
            </span>
          </button>
          {holidayHoursOpen && (
            <ul className={styles.holidayHoursList}>
              {holidayHoursFiltered.map((item, i) => {
                let dayHoursFormatted = ''
                if (item.openingHour && item.closingHour) {
                  dayHoursFormatted = `${formatMilitaryTime(item.openingHour)} - ${formatMilitaryTime(
                    item.closingHour,
                  )}`
                }
                if (item.isClosed) {
                  dayHoursFormatted = i18n('locationClosed') || ''
                }

                return (
                  <li
                    key={i}
                    className={styles.holidayHoursList__item}
                  >
                    <p className={styles.holidayHoursList__itemTitle}>{item.title}</p>
                    <p className={styles.holidayHoursList__itemHours}>{dayHoursFormatted}</p>
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      )}
    </div>
  )
}

LocationHeroHours.displayName = 'LocationHeroHours'

export default LocationHeroHours
