'use client'

import classnames from 'classnames'
import styles from './GiftCardGrid.module.scss'
import SanityImage from '@/components/SanityImage/SanityImage'
import useInView from '@/hooks/use-in-view'
import { CSSProperties } from 'react'

const GiftCardGrid = ({ className, items }: SanityGiftCardGrid) => {
  const { setElementToObserve, isInView } = useInView()

  if (!items?.length) return null

  return (
    <div
      ref={ref => {
        setElementToObserve(ref)
      }}
      className={classnames(styles.GiftCardGrid, className, { [styles.inView]: isInView })}
      data-is-even={items.length % 2 === 0}
    >
      <div className={styles.inner}>
        <div className={styles.grid}>
          {items.map((item, i) => (
            <div
              key={i}
              className={styles.itemContainer}
              style={{ '--stagger-delay': `${i * 0.1}s` } as CSSProperties}
            >
              <SanityImage
                image={item}
                className={styles.image}
                breakpoints={{
                  tablet: {
                    width: 1200,
                    image: item,
                  },
                  xs: {
                    width: 800,
                    image: item,
                  },
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

GiftCardGrid.displayName = 'GiftCardGrid'

export default GiftCardGrid
