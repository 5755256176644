'use client'

import classnames from 'classnames'
import styles from './Reviews.module.scss'
import SanityImage from '@/components/SanityImage/SanityImage'
import AnimateContainer from '@/components/AnimateContainer/AnimateContainer'

const Reviews = ({ className, title, review, author, designation, image, textSide }: SanityReviews) => {
  if (!title || !review || !author || !image?.asset?.url) return null

  return (
    <div
      className={classnames(styles.Reviews, className)}
      data-text-side={textSide}
    >
      <div className={styles.inner}>
        <AnimateContainer
          animationType="fadeIn"
          className={styles.textSide}
        >
          <h1 className={styles.title}>{title}</h1>
          <div className={styles.reviewContainer}>
            <blockquote className={styles.quote}>“{review}&rdquo;</blockquote>
            <address className={styles.authorDesignationContainer}>
              <span className={styles.author}>{author}</span>
              {designation && <span className={styles.designation}>&nbsp;-&nbsp;{designation}</span>}
            </address>
          </div>
        </AnimateContainer>
        <AnimateContainer
          animationType="fadeIn"
          className={styles.imageSide}
        >
          <div className={styles.imageContainer}>
            <SanityImage
              className={styles.image}
              image={image}
              breakpoints={{
                tablet: {
                  width: 1300,
                  image,
                },
                xs: {
                  width: 900,
                  image,
                },
              }}
            />
          </div>
        </AnimateContainer>
      </div>
    </div>
  )
}

Reviews.displayName = 'Reviews'

export default Reviews
