import { PortableText, PortableTextReactComponents } from '@portabletext/react'
import Link from '@/components/Link/Link'
import styles from './TimelineItem.module.scss'

const portableTextSerializer: Partial<PortableTextReactComponents> = {
  marks: {
    link: ({ value, text }) => {
      return (
        <Link
          link={{
            ...value,
            label: text,
          }}
          className={styles.richTextLink}
        />
      )
    },
  },
  block: {
    normal: ({ children }) => <p className={styles.p}>{children}</p>,
  },
  listItem: {
    bullet: ({ children }) => <li className={styles.li}>{children}</li>,
  },
  list: {
    bullet: ({ children }) => <ul className={styles.ul}>{children}</ul>,
  },
}

function TimelineItemRichText({ content }: SanityContentBlockProps) {
  return (
    <PortableText
      value={content}
      onMissingComponent={false}
      components={portableTextSerializer}
    />
  )
}

export default TimelineItemRichText
