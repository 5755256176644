import { PortableText, PortableTextReactComponents } from '@portabletext/react'
import Link from '@/components/Link/Link'
import styles from './AccordionItemsWithSideNavigation.module.scss'

const portableTextSerializer: Partial<PortableTextReactComponents> = {
  marks: {
    link: ({ value, text }) => {
      return (
        <Link
          link={{
            ...value,
            label: text,
          }}
          className={styles.richTextLink}
        />
      )
    },
  },
  block: {
    normal: ({ children }) => <p className={styles.descriptionText}>{children}</p>,
  },
  listItem: {
    bullet: ({ children }) => <li className={styles.descriptionListElement}>{children}</li>,
  },
  list: {
    bullet: ({ children }) => <ul className={styles.descriptionUnorderedList}>{children}</ul>,
  },
}

function AccordionItemsWithSideNavigationRichText({ content }: SanityContentBlockProps) {
  return (
    <PortableText
      value={content}
      components={portableTextSerializer}
      onMissingComponent={false}
    />
  )
}

export default AccordionItemsWithSideNavigationRichText
